import React from 'react'
import Footer from './Footer'

export default function BlogList() {
  return (
    <>
     <div style={{maxWidth: '90vw', width: 1400, margin: '2rem auto', minHeight: 'calc(100vh - 250px)'}}>
      <h1 style={{color: '#A28F89'}}><span style={{fontWeight: 100}}>Welcome to the </span>Om Yoga Blog</h1>
      <p>Nourishing Souls, Connecting Hearts - Dive into a Community of Wellness, Enlightenment, and Unity</p>

      <p></p>
    </div>
    <Footer />
    </>
  )
}
