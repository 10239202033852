import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';

const RequireAuth = (props) => {
    const {currentUser, role} = useAuth()
    let location = useLocation();
    return (currentUser && (props.requiredRole === role)) ? <Outlet /> : <Navigate to="/" state={{from : location}} replace/>
};

export default RequireAuth;