import React from 'react'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

export default function ExploreStudios({studios, loading}) {
    let navigate = useNavigate()

  return (
    <div style={{display:'flex', flexWrap:'wrap', padding: '2rem', minHeight: '70vh'}}>
        
        { loading ?
        <Skeleton style={{width: '20vw', height: '20vw', borderRadius: 12, margin: '1rem'}} count={20} inline={true}/> 

        :
        studios.map((studio, i) => {
            return (
                <div className='studio-container hover' key={i} onClick={() => navigate(`/explore/studios/${studio._id}`) }>
                    <img className='studio-logo' src={studio.logo}/>
                    <div>
                        <p style={{fontWeight: 700, fontSize: 18}}>{studio.studioName}</p>
                        <p className='teacher-location'>{studio.town}, {studio.county}</p>
                    </div>
                </div>
            )
        })}
    </div>
  )
}
