import React, { useState } from 'react'
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { toast } from 'react-toastify';
import axios from 'axios'
import './PassPaymentForm.css'
import { useAuth } from '../../../Context/AuthContext'


const PassPaymentForm = ({ onSuccess, student, pass, connectedAccountId, setOpen }) => {

    let { role } = useAuth()
    const stripe = useStripe()
    const elements = useElements()
    const [isProcessing, setIsProcessing] = useState(false);

    const fetchPaymentIntentClientSecret = async () => {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/payments/create-customer-payment-intent`,
            {
                userId: student.uid,
                userRole: role,
                name: student.displayName,
                email: student.email,
                amount: pass.price,
                connectedAccountId,
                pass: pass._id,
            }
        );

        const { clientSecret, error } = response.data;
        return { clientSecret, error };
    };


    const handlePayment = async () => {
        setIsProcessing(true)

        console.log("handle payment")

        try {
            const { paymentMethod, error: pmError } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
                // for inclusion consider retention of info (e.g. GDPR) vs security (e.g. proof of id)
                // note that FDM client payment includes email but om mobile excludes
                /* billing_details: {
                    email: cardEmail,
                    name: user.displayName,
                }, */
            })

            if (pmError) {
                setIsProcessing(false)
                toast.error(pmError.message)
                return;
            }

            // TODO consider using LogToServer

            // Notes on checking card country:
            // Client side checks are subject to spoofing but:
            //   - worst case from app perspective a foreign card is used and it incurs a higher stripe fee
            //   - worst case from user perspective, they prevent themselves from being able to book
            // However, UX is affected. A user who might have expected to be able to use the service with
            // their foreign card cannot. This should be present in terms and conditions and should be rare
            if (paymentMethod?.card?.country !== 'GB') {
                setIsProcessing(false)
                console.log("Can't process non UK cards", paymentMethod?.card?.country)
                toast.error("Apologies. Payments are only accepted from UK issued cards.")
                return;
            }

            // create the intent on backend and fetch client secret
            let { clientSecret, error } =
                await fetchPaymentIntentClientSecret();

            /* as above, consider whether these are essential identification or a security risk */
            const billingDetails = { /* name: cardName, email: cardEmail */ };

            // confirm the payment
            if (error) {
                setIsProcessing(false)
                console.log("Problem while setting up payment:", error);
                toast.error("Problem while setting up payment")
            } else {
                const { paymentIntent, error: cpError  } = await stripe.confirmCardPayment(
                    clientSecret,{
                    payment_method: {
                        card: elements.getElement(CardNumberElement),
                        billing_details: billingDetails,
                    }
                })

                if (cpError) {
                    setIsProcessing(false)
                    console.log("Problem confirming payment intent:", cpError)
                    toast.error(`Payment Issue: ${cpError.message}`)
                    return
                } else if (paymentIntent) {
                    console.log("Payment intent confirmed", paymentIntent)
                    // no alerting of success at this point since
                    // still to handle registering the pass itself
                    setIsProcessing(false)
                    setOpen(false)
                    // call the onSuccess function passed from the parent
                    onSuccess();
                }
            }
        } catch (error) {
            setIsProcessing(false);
            console.error('Error creating payment:', error);
            toast.error("Failure during payment process. No payment has been taken.")
        }
    };

    return (
        <div className="card-element-container">
            <div className="card-element full-width">
                <CardNumberElement />
            </div>
            <div className="card-element narrow-width">
                <CardExpiryElement />
            </div>
            <div className="card-element narrow-width">
                <CardCvcElement />
            </div>

            <div style={{
                display:'flex',
                flexWrap:'wrap',
                justifyContent:'space-between',
                width: '100%'
            }}>
                <button
                    className='om-btn'
                    style={{width: '45%', marginTop: 20}}
                    onClick={handlePayment}
                    disabled={isProcessing}
                >
                    {isProcessing ? 'Processing...' : `Pay £${pass.price}`}
                </button>

                <button className='cancel-btn' onClick={() => setOpen(false)}>
                    Cancel
                </button>
            </div>

            <p className='passTxt'>
                This purchase is non-refundable.
            </p>
        </div>
    )
}

export default PassPaymentForm
