import React from 'react'
import './Navbar.css'
import logo from '../../Assets/om.png'
import { Link } from 'react-router-dom'
import DropDownMenu from '../PublicUI/DropDownMenu'

export default function StudentNavbar() {
  return (
    <header id='explore-header'>

        <div style={{display: 'flex', justifyContent: 'space-between', width: '90vw', maxWidth: 1200, margin: '0 auto'}}>
            <Link to={'/explore'} style={{display:'flex', alignItems:'center', textDecoration:'none'}}>
                <img style={{width: 50, height: 50}} src={logo} alt='Om Yoga booking app logo' />
                <p className='mobile-remove' style={{fontFamily:'Arual', margin: '0 10px', fontSize: 28}}>Om Yoga</p>
            </Link>

            <DropDownMenu />
        </div>
   
    </header>
  )
}
