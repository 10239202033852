import React, { useState } from 'react'
import close from '../../../Assets/close.png'
import stripeLogo from '../../../Assets/stripe.png'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useAuth } from '../../../Context/AuthContext';
import PassPaymentForm from '../PaymentForms/PassPaymentForm';

export default function ModalPassPurchase({ onSuccess, setOpen, pass, teacher }) {

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
    const [saving, setSaving] = useState(false)
    let { currentUser } = useAuth()

    const handleContainerClick = (e) => {
        e.stopPropagation(); // Prevents click event from bubbling up to the modal div
    };

    const handlePaymentSuccess = () => {

        console.log('Payment Successful');

        let payload = {
            passName: pass.passName,
            passId: pass._id,
            totalStamps: pass.classes,
            cost: pass.price,
            stampsRemaining: pass.classes,
            teacherId: pass.teacherId,
            teacherName: pass.teacherName,
            studentId: currentUser.uid,
            studentName: currentUser.displayName,
            weeksValid: pass.weeksValid,
            expiry: moment().add(pass.weeksValid, 'weeks').format(),
            purchaseDate: moment().format(),
            email: currentUser.email  // Used for email
        }

        setSaving(true)
        axios.post(`${process.env.REACT_APP_API_URL}/passes/buy-class-pass`, payload)
        .then((res) => {
            toast.info(res.data)
            setSaving(false)

            // call the onSuccess function passed from the parent
            onSuccess();
        })
        .catch((error) => {
            // TODO alerting needed here. Potential that payment is taken but
            // the pass itself is not stored in schema
            setSaving(false)
            console.log("Problem purchasing pass:", error)
            toast.error(`Problem purchasing pass. Please contact support`)
        })
    };

    return (
        <div className='modal' onClick={() => setOpen(false)}>
            <div className='modal-container' onClick={handleContainerClick}>
                <img className='hover close-modal-btn'  src={close} alt='Close Om Yoga pass purchase box' onClick={() => setOpen(false)}/>

                <h3 className='modal-header'>Purchase Pass</h3>

                <div className='divider' />

                <div style={{textAlign:'center'}}>
                    <h4>{pass.passName}</h4>
                    <p>£{pass.price}</p>
                    <p>{pass.classes} classes</p>
                    <p>Valid for {pass.weeksValid} weeks</p>
                </div>

                <img style={{margin: '0 auto'}} className="stripe-attribution" src={stripeLogo} alt="Stripe logo"/>

                {!saving &&
                    <Elements stripe = {stripePromise}>
                        <PassPaymentForm
                            onSuccess={handlePaymentSuccess}
                            student={currentUser}
                            pass={pass}
                            connectedAccountId={teacher.connectData?.accountId}
                            setOpen={setOpen}
                        />
                    </Elements>
                }
            </div>
        </div>
    )
}
