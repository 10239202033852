import React from 'react'
import './TeacherPage.css'
import Footer from './Footer'
import Header from './Header'
import circle from '../../Assets/circle.webp'
import mobile from '../../Assets/mobile.webp'
//App icons
import apple from '../../Assets/apple-black.webp'
import google from '../../Assets/google-black.webp'
//Icons
import discover from '../../Assets/discoverability.webp'
import payments from '../../Assets/payments.webp'
import bookings from '../../Assets/bookings.webp'
import community from '../../Assets/community.webp'
import time from '../../Assets/time.webp'
//Carousel
import Carousel from 'react-multi-carousel';


const features = [
  {feature: 'Boost Your Discoverability', image: discover, description: 'Help potential students find you - with Om Yoga, users can locate you by your name or studio, ensuring maximum visibility for your yoga classes.'},
  {feature: 'Instant Bookings', image: bookings, description: 'Simplify class attendance with instant booking capabilities. Students can easily find your classes and reserve their spot in your classes at the touch of a button.'},
  {feature: 'Swift Payments', image: payments, description: 'Receive payments directly to your bank account, ensuring a secure and hassle-free financial transaction experience you and your students.'},
  {feature: 'Build Your Community', image: community, description: 'Create a personalised profile which students can follow. Grow your community and share news and special events.'},
  {feature: 'Effortless Time Management', image: time, description: 'Take control of your time and classes effortlessly. With Om Yoga, creating and sharing your timetable becomes a breeze.'},

]


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 748 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 748, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

export default function TeacherPage() {
  return (
    <>
      <Header />

      <h2 id='teacher-mobile-header'>Simplify your yoga business with <span style={{color:'#A28F89'}}>Om Yoga.</span></h2>

      <div id='teacher-hero'>
        <div id='teacher-hero-info'>
          <h2 style={{fontSize: 42, fontWeight: 200, margin: 0}}>Simplify your yoga business with Om Yoga.</h2>
          <p style={{fontSize: 20}}>With effortless scheduling, instant payments and community building at your fingertips.</p>
          <div>
            <a href='https://apps.apple.com/uk/app/om-yoga/id6470202583' target='_blank'>
              <img style={{height: 52, marginRight: 10}} src={apple} alt='app store link to Om Yoga booking app for studios '/>
            </a>
            <a  href='https://play.google.com/store/apps/details?id=com.omyoga.omyoga&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank'>
              <img style={{height: 52}} src={google} alt='app store link to Om Yoga booking app for studios '/>
            </a>

          </div>
        </div>
      </div>

      <div id='mobile-hero-info'>
          <p style={{fontSize: 20, textAlign:'center', color:'#A28F89'}}>With effortless scheduling, instant payments and community building at your fingertips.</p>
          <div style={{width: '90vw', textAlign:'center'}}>
            <a href='https://apps.apple.com/uk/app/om-yoga/id6470202583' target='_blank'>
              <img style={{height: 52, margin: 5}} src={apple} alt='app store link to Om Yoga booking app for studios '/>
            </a>
            <a  href='https://play.google.com/store/apps/details?id=com.omyoga.omyoga&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank'>
              <img style={{height: 52, margin: 5}} src={google} alt='app store link to Om Yoga booking app for studios '/>
            </a>

          </div>
        </div>

      <div id='teacher-features'>
      {features.map((feature, i) => {
        return (
          <div style={{ position: 'relative', display: 'flex', alignItems:'center'}} key={i}>
            <div className={i === 1 ? 'left-brown' : i === 3 ? 'right-brown' : ""} style={i === 3 ? {padding: '50px 100px'} : {padding: '50px 10%'}}>
              <div style={{display:'flex', alignItems:'center', width: 600}}>
                <img style={{width: 80, height: 80, marginRight: 50}} src={feature.image} alt='Om Yoga booking app for yoga teachers and studios'/>
      
                <div className='teacher-feature-txt'>
                  <h3 style={{fontSize: 28, fontWeight: 100, margin: '10px 0'}}>{feature.feature}</h3>
                  <p style={{fontSize: 18, color: '#A28F89'}}>{feature.description}</p>
                </div>
      
              </div>
              {i === 1 && <img id='phone-img' src={mobile}/>}

              {i === 3 && <img id='circle-img' src={circle}/>}
            </div>

        </div>
        )
      })}
      </div>

      <div id='teacher-carousel'>
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
        {features.map((feature, i) => {
        return (
          <div className='mobile-feature'  key={i}>
                <img style={{width: 80, height: 80, marginRight: 50, objectFit: 'contain'}} src={feature.image} alt='Om Yoga booking app for yoga teachers and studios'/>
      
                <div>
                  <h3 style={{fontSize: 28, fontWeight: 100, margin: '10px 0'}}>{feature.feature}</h3>
                  <p style={{fontSize: 18, color: '#313131'}}>{feature.description}</p>
                </div>
          </div>
        )
      })}
    </Carousel>
    </div>

    <img id='mobile-student-phone' src={mobile}/>


     

      <div id='teacher-footer'>
        <div id='teacher-footer-info'>
          <h2 style={{fontSize: 40, fontWeight: 200, color:'#313131'}}>Download Om Yoga the ultimate tool for yoga teachers!</h2>
          <div>
          <a href='https://apps.apple.com/uk/app/om-yoga/id6470202583' target='_blank'>
              <img style={{height: 52, marginRight: 10}} src={apple} alt='app store link to Om Yoga booking app for studios '/>
            </a>
            <a  href='https://play.google.com/store/apps/details?id=com.omyoga.omyoga&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank'>
              <img style={{height: 52}} src={google} alt='app store link to Om Yoga booking app for studios '/>
            </a>

          </div>
        </div>
      </div>

      <Footer />

    </>
  )
}
