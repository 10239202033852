import React, { useState} from 'react'
import './FAQ.css'
import close from '../../Assets/close.webp'

let questions = [
    {question: "How can Om Yoga's app benefit my yoga studio's scheduling and organisation?",
     answer: "Om Yoga's app streamlines your studio's operations by providing an intuitive platform to create timetables, manage class schedules, and handle financial transactions. With easy access for both teachers and students, you can enhance efficiency for everyone involved."
    },
    {question: "Is my studio's financial information secure on the Om Yoga platform?",
     answer: "Yes, Om Yoga prioritises the security of your financial data. We use state-of-the-art encryption and adhere to industry-leading security standards to ensure that your studio's financial information and payment details are safe and protected."
    },
    {question: "Can I customise class schedules and offerings based on my studio's unique needs?",
     answer: "Absolutely! Om Yoga is designed with flexibility in mind. You have the freedom to customise class schedules, manage different types of classes, and adapt the app to your studio's specific requirements. Tailor the platform to reflect the unique offerings of your yoga studio."
    },
    {question: "How can Om Yoga help with student and teacher communication?",
     answer: "Om Yoga facilitates seamless communication between students and teachers. The app includes features such as messaging and notifications, allowing teachers to easily communicate class updates, share resources, and engage with students. This ensures a connected and informed yoga community."
    },
    {question: "Can I upgrade my plan at any time to access additional features and services?",
     answer: "Yes, absolutely! Om Yoga offers the flexibility to upgrade your plan at any time. Whether your studio is expanding, or you simply want access to more advanced features, upgrading is a seamless process that allows you to enhance your experience with our platform."
    },
    {question: "How does Om Yoga assist with managing payments and financial transactions for my studio?",
     answer: "Om Yoga simplifies payment management for your studio. The app includes features for processing payments, tracking revenue, and managing financial transactions effortlessly. From class fees to workshop payments, our platform ensures secure and efficient financial operations for your yoga business."
    },
    {question: "Is technical support available if I encounter any issues with the Om Yoga app?",
     answer: "Yes, Om Yoga is dedicated to providing excellent customer support. Our technical support team is readily available to assist you with any issues or queries you may have. Feel free to reach out through our support channels, and we'll ensure a prompt and helpful resolution."
    },
    {question: "How does Om Yoga help in promoting my studio and attracting new students?",
     answer: "Om Yoga includes features to promote your studio and attract new students. From customisable profiles to marketing tools, our platform empowers you to showcase your studio's unique offerings, making it easier for potential students to discover and join your yoga community."
    },
]

export default function FAQ() {

    const [open, setOpen] = useState("")

    const handleClick = (index) => {
        if(open === index){
            setOpen("")
        } else {
            setOpen(index)
        }
    }

  return (
    <div id='faqs'>

        <div id='balance'>
            <p className='mobile-remove'>Navigate your yoga business easily with Om Yoga.</p>
        </div>

        <h2>Frequently Asked Questions</h2>

        <div>
            {questions.map((item, i) => {
                return (
                    <div key={i} className={open === i ? 'faq-container-open' : 'faq-container'} onClick={() => handleClick(i)}>
                        <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <h6>{item.question}</h6>
                            <img className={open === i ? 'open-close' : 'close-close'}  src={close} alt='Om Yoga studio close booking app question'/>
                        </div>
                        <p className={open === i ? 'open-answer' : 'close-answer'} >{item.answer}</p>
                    </div>
                )
            })}
        </div>
    </div>
  )
}
