import React, {useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import Modal from 'react-modal';
import './Calendar.css'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import { toast } from 'react-toastify'
import moment from 'moment'
import Switch from "react-switch";

Modal.setAppElement('#root');

export default function Calendar() {

  const { currentUser } = useAuth()
  const [date, setDate] = useState("")
  const [start, setStart] = useState()
  const [end, setEnd] = useState()
  const [classes, setClasses] = useState([])
  const [events, setEvents] = useState([])
  const [classChoice, setClassChoice] = useState("")
  const [modalIsOpen, setIsOpen] = useState(false);
  const [eventModalIsOpen, setEventOpen] = useState(false);

  //New event state
  const [className, setClassName] = useState("")
  const [venueName, setVenueName] = useState("")
  const [venueAddress, setVenueAddress] = useState("")
  const [googleMaps, setGoogleMaps] = useState("")
  const [price, setPrice] = useState("")
  const [spaces, setSpaces] = useState("")
  const [classId, setClassId] = useState("")
  const [classType, setClassType] = useState("")
  const [bookingLink, setBookingLink] = useState("")
  const [saving, setSaving] = useState(false)
  //View event state
  const [event, setEvent] = useState("")
  const [students, setStudents] = useState([])



  useEffect(() => {
    getClasses()
    getEvents()
  }, [])

  const getEvents = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/events/get-events-by-teacher`, {teacherId: currentUser.uid})
    .then((res) => {
      setEvents(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  useEffect(() => {
    if(classChoice){
      let classInfo = classes.filter((myClass) => myClass._id === classChoice )
      setClassName(classInfo[0].className)
      setVenueName(classInfo[0].venueName)
      setVenueAddress(classInfo[0].venueAddress)
      setGoogleMaps(classInfo[0].googleMaps)
      setPrice(classInfo[0].price)
      setSpaces(classInfo[0].spaces)
      setStart(classInfo[0].startTime)
      setEnd(classInfo[0].endTime)
      setClassId(classInfo[0]._id)
      setClassType(classInfo[0].classType)
      setBookingLink(classInfo[0].bookingLink)

    }

  }, [classChoice])

  const getClasses = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/classes/get-classes-by-teacher`, {teacherId: currentUser.uid})
    .then((res) => {
      setClasses(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  function openModal() {
    setIsOpen(true);
  }

  function openEventModal() {
    setEventOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setClassType("");setBookingLink("");setDate("");setClassChoice("")
  }

  function closeEventModal() {
    setEventOpen(false);
  }


  const handleDateClick = (event) => {
    setDate(event.dateStr)
    openModal()
  }

  const handleEventClick = (event) => {
    setEvent(event.event)
    openEventModal()
    getStudentsOnClass(event.event._def.extendedProps._id)
  }

  const getStudentsOnClass = (eventId) => {
    axios.post(`${process.env.REACT_APP_API_URL}/bookings/get-students-on-class`, {eventId})
    .then((res) => {
      console.log(res.data)
      setStudents(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const createEvent = () => {

    let payload = {
      teacherId: currentUser.uid,
      classId,
      date,
      title: `${className} at ${venueName}`,
      start: `${date} ${start}`,
      end: `${date} ${end}`,
      className,
      venueName,
      venueAddress,
      googleMaps,
      price,
      spaces,
      spacesAvailable: spaces,
      classType,
      bookingLink
    }

    if(classType !== 'Studio' && (!date || !className || !venueName || !start || !end || !price || !spaces) ){
      toast.error("Please add required fields")
    } else {
      setSaving(true)
      axios.post(`${process.env.REACT_APP_API_URL}/events/create-new-event`, payload)
      .then(() => {
        toast.success("Event created")
        setSaving(false)
        closeModal()
        setClassChoice("")
        getEvents()
      })
      .catch((e) => {
        console.log(e)
        setSaving(false)

      })
    }
    
  }

  const handlePaymentToggle = (checked, booking) =>{
    axios.post(`${process.env.REACT_APP_API_URL}/bookings/set-paid`, {_id: booking._id, checked})
    .then(() => {
      getStudentsOnClass(booking.eventId)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const cancelEvent = (_id) => {
    console.log(_id)
    if(window.confirm("Are you sure you want to delete?")){
      axios.post(`${process.env.REACT_APP_API_URL}/events/cancel-event`, {_id})
      .then(() => {
        getEvents()
        closeEventModal()
      })
      .catch((e) => {
        console.log(e)
      })
    } else {
      return
    }
  }


  return (
    <div className='outlet'>
        <div style={{width: 1200, maxWidth: '90vw', margin: '1rem auto'}}>
            <p>Add new classes by clicking on the calendar date and selecting from your list of classes</p>
            <FullCalendar
                plugins={[ dayGridPlugin, interactionPlugin ]}
                dateClick={(event) => handleDateClick(event)}
                eventSources={[events]}
                eventClick={(event) => handleEventClick(event)}
                eventDisplay='block'
                eventClassNames={(arg) =>{
                 
                    return [ 'normal' ]
                  
                }}
                firstDay={1}

            />
        </div>

      {/*Create event*/}
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{...customStyles, overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(70, 70, 70, 0.5)',
        }}}
        contentLabel="Example Modal"
      >

          <input className='event-input' type='date' value={date} onChange={(e) => setDate(e.target.value)} />

          <select className='event-input' value={classChoice} onChange={(e) => setClassChoice(e.target.value)}>
            <option value='' disabled>Select class</option>
            {classes.map((myClass, i) => {
              return (
                <option key={i} value={myClass._id}>
                  {myClass.className} at {myClass.venueName}
                </option>
              )
            })}
          </select>

          {classChoice &&
          <>
            <input className='event-input' placeholder='Class name eg. Ashtanga' value={className} onChange={(e) => setClassName(e.target.value)}/>
            <label>Start time</label>
            <input className='event-input' type='time' value={start} onChange={(e) => setStart(e.target.value)}/>
            <label>End time</label>
            <input className='event-input' type='time' value={end} onChange={(e) => setEnd(e.target.value)}/>
            <input className='event-input' placeholder='Venue name' value={venueName} onChange={(e) => setVenueName(e.target.value)}/>
            <input className='event-input' placeholder='Venue address' value={venueAddress} onChange={(e) => setVenueAddress(e.target.value)}/>
            <input className='event-input' placeholder='Google map link' value={googleMaps} onChange={(e) => setGoogleMaps(e.target.value)}/>
            <input className='event-input' type='number' placeholder='Class price per person' value={price} onChange={(e) => setPrice(e.target.value)}/>
           {classType === 'Independent' ? 
           <>
            <label>Spaces</label>
            <input className='event-input' type='number' placeholder='Number of spaces' value={spaces} onChange={(e) => setSpaces(e.target.value)}/>
           </>
           :
           <>
            <label>Booking link</label>
            <input className='event-input' placeholder='Booking link' value={bookingLink} onChange={(e) => setBookingLink(e.target.value)}/>
           </>
           } 
          </>
          }
         <div>
            <button onClick={createEvent} disabled={saving} className='om-btn' style={{marginRight: 10}}>{saving? "Saving..." : "Create class"}</button>
            <button className='modal-close-btn' onClick={closeModal}>close</button>   
         </div>
         
    
      </Modal>
      {/*View event*/}
      <Modal
        isOpen={eventModalIsOpen}
        onRequestClose={closeEventModal}
        style={{...customStyles, overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(70, 70, 70, 0.5)',
          
        }}}
        contentLabel="Modal"
      >


          {event &&
          <div style={{width: '100%'}}>
            <h2>{moment(event.start).format('DD MMM YYYY')}</h2>
            <h3>{event.title}</h3>
            <h4>{event._def.extendedProps.spacesAvailable} spaces available</h4>

            {students.length > 0 && 
            <div style={{ width:'100%', display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
              <h4>Students</h4>
              <h4>Paid</h4>
            </div>
            }
            {students.map((student, i) => {
              return (
                <div key={i} style={{width:'100%', display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
                  <p>{student.studentName}</p>
                  <Switch onChange={(checked) => handlePaymentToggle(checked, student)}  checked={student.paid} />
                </div>
              )
            })}
          </div>
          }
         
        <button className='cancel-btn' onClick={() => cancelEvent(event._def.extendedProps._id)}>Cancel</button>

        <button className='modal-close-btn' onClick={closeEventModal}>close</button>
    
      </Modal>
        
    </div>
  )
}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display:'flex',
    flexDirection:'column',
    width: 600,
    maxWidth: '95vw',
    height: 'auto',
    maxHeight: '90vh',
    overflowY:'scroll',
    textAlign:'center'
  },
};