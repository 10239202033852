import React, { useState} from 'react'
import './ContactModal.css'
import contact from '../../Assets/contact.webp'
import { toast } from 'react-toastify'
import axios from 'axios'

export default function ContactModal({enquiry, setEnquiryType, setModalOpen}) {

  const handleSelect = (selection) => {
    setEnquiryType(selection)
  }

  const [name, setName] = useState("")
  const [studio, setStudio] = useState("")
  const [number, setNumber] = useState("")
  const [email, setEmail] = useState("")
  const [msg, setMsg] = useState("")
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    let payload = {
      name, studio, number, email, msg, enquiry
    }

    if(!name || !email){
      toast.error("Please add name and email")
    } else {
      setSending(true)
      axios.post(`${process.env.REACT_APP_API_URL}/support/website-enquiry`, payload)
      .then(() => {
        setSending(false)
        setSent(true)
      })
      .catch((e) => {
        console.log(e)
        toast.error("Error sending")
        setSending(false)
      })
    }
  }



  return (
    <div id='contact-modal'>
        <div id='modal-container'>
            <button id='close-modal' onClick={() => setModalOpen(false)}>x</button>

            <img id='modal-img' src={contact}/>

            <form id='contact-form'>
              {sent ?
                <h2 style={{marginTop: '50%', textAlign:'center'}}>Successfully sent!</h2>
                :
                <>
                  <h2 style={{margin: 0}}>Get In Touch</h2>
                  <p>Contact us today to arrange a free demo of Om Yoga and enhance the success of your studio.</p>

                  <div>
                      <input type='radio' value="Demo" checked={enquiry === "Demo"} onChange={() => handleSelect("Demo")}/>
                      <label>Demo Request</label>
                      <input  type='radio' value="Callback" checked={enquiry === "Callback"} onChange={() => handleSelect("Callback")}/>
                      <label>Request Callback</label>
                      <input  type='radio' value="General" checked={enquiry === "General"} onChange={() => handleSelect("General")}/>
                      <label>General enquiry</label>
                  </div>
                

                  <input className='contact-input' type='text' placeholder='Name*' value={name} onChange={(e) => setName(e.target.value)}/>
                  <input className='contact-input' type='text' placeholder='Studio Name' value={studio} onChange={(e) => setStudio(e.target.value)}/>
                  <input className='contact-input' type='text' placeholder='Contact Number' value={number} onChange={(e) => setNumber(e.target.value)}/>
                  <input className='contact-input' type='email' placeholder='Email*' value={email} onChange={(e) => setEmail(e.target.value)}/>
                  <textarea id='contact-msg' placeholder='Message' value={msg} onChange={(e) => setMsg(e.target.value)}/>

                  <button id='send-btn' onClick={handleSubmit} disabled={sending}>{sending ? "Sending..." : "Send"}</button>

                  <p style={{fontSize: 12, color: 'grey'}}>By submitting this form you consent to Om Yoga contacting you for the purpose of discussing the services offered by Om Yoga</p>

                </>

              }
             
            </form>

        </div>

       
    </div>
  )
}
