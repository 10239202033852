import React, { useState, useRef, useEffect } from 'react'
import { useAuth } from '../../Context/AuthContext'
import logout from '../../Assets/logout.png'
import {  signOut } from "firebase/auth";
import { auth } from '../../Firebase'
import ModalLogin from './Modals/ModalLogin'
import ModalSignUp from './Modals/ModalSignUp'
import user from '../../Assets/profile-user.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword} from "firebase/auth";
import { toast } from 'react-toastify'

export default function DropDownMenu() {

    let { currentUser, role } = useAuth()
    let location = useLocation();
    let navigate = useNavigate()

    const [showPopover, setShowPopover] = useState(false)
    const [openLogin, setOpenLogin] = useState(false)
    const [openSignup, setOpenSignup] = useState(false)

    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowPopover(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);


    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            window.location.href = '/';  // Redirect to home page
        }).catch((error) => {
            // An error happened.
        });
    }

    const handleLogin = () => {

        if (role === 'Teacher') {
            toast.error("Teacher account not yet supported")
        } else if (role === 'Student'){
            // otherwise this means they are an artist there are no other roles as yet
            navigate('/students')
        } else if(role === 'Studio'){
            toast.error("Please log in to Om Yoga Studio")
        }
      }

  return (
    <div
        className='hover'
        ref={dropdownRef}
        style={{display:'flex', alignItems:'center', position: 'relative'}}
        onClick={() => setShowPopover(!showPopover)}
        >
                {openLogin && <ModalLogin setOpen={setOpenLogin} setOpenSignup={setOpenSignup}/>}
                {openSignup && <ModalSignUp setOpen={setOpenSignup} setOpenLogin={setOpenLogin}/>}

                {location.pathname === '/'&&
                    <Link to={'/explore'} className='desktop-remove'>
                        <button id='explore-btn'>Explore</button>
                    </Link>
                }
                {currentUser ?
                <p className='mobile-remove' style={{marginRight: 10}}>{currentUser.displayName}</p>
                :
                <p className='mobile-remove' style={{marginRight: 10}}>Log in or create account</p>
                }
                <img style={{width: 40, height: 40}} src={user} alt='Om Yoga booking user icon'/>
                {showPopover &&
                <div className='header-popover'>
                    {currentUser ?
                    <>
                        <p className='hover-with-background popover-item' onClick={handleLogin}>
                            My account
                        </p>
                        <div className='divider'/>

                        <div className='hover-with-background popover-item' style={{display:'flex', alignItems: 'center'}} onClick={handleLogout}>
                            <img style={{height: 20, width: 20, marginRight: 10}} src={logout} alt='Om Yoga logout button'/>
                            <p style={{margin: 0}}>Logout</p>
                        </div>
                    </>
                    :
                    <>
                        <p className='hover-with-background popover-item' onClick={() => setOpenSignup(true)}>Sign Up</p>
                        <p className='hover-with-background popover-item' onClick={() => setOpenLogin(true)}>Login</p>

                        {/*<div className='divider'/>

                        <p className='hover-with-background popover-item'>Support</p>*/}
                    </>

                    }

                </div>
                }
        </div>
  )
}
