import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import './Profiles.css'
import moment from 'moment'
import map from '../../Assets/googlemap.png'
import share from '../../Assets/share.png'
import follow from '../../Assets/add.png'
import back from '../../Assets/back.png'
import ProfileHeader from './ProfileHeader'
import Footer from '../Landing/Footer'
import ModalShare from './Modals/ModalShare'
import { useAuth } from '../../Context/AuthContext'
import ModalLogin from './Modals/ModalLogin'
import website from '../../Assets/website.png'
import fb from '../../Assets/facebook.png'
import insta from '../../Assets/instagram.png'
import ModalSignUp from './Modals/ModalSignUp'

export default function StudioProfile() {
    
    let { id } = useParams()
    let { currentUser } = useAuth()
    let navigate = useNavigate()
    const [info, setInfo] = useState("")
    const [events, setEvents] = useState([])
    const [shareOpen, setShareOpen] = useState(false)
    const [loginOpen, setLoginOpen] = useState(false)
    const [openSignup, setOpenSignup] = useState(false)

    const textareaRef = useRef(null);

    useEffect(() => {
        textareaRef.current.style.height = "0px";
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + "px";
    }, [info]);


    useEffect(() => {
        //Get Teacher info
        getProfile()
        //Get classes associated with teacher
        getEvents()
        //Get passes for teacher
    }, [])

    const getProfile = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/studios/get-studio-by-id`, {id})
        .then((res) => {
            setInfo(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const getEvents = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/events/get-future-events-by-studio`, {studioId: id})
        .then((res) => {
            console.log(res.data)
            setEvents(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const handleFollow = () => {
        if(!currentUser){
            //Open login modal
            setLoginOpen(true)
        } else {
            //Handle follow
            console.log(currentUser)
        }

    }

  return (
    <div>
        {shareOpen && 
            <ModalShare 
                setOpen={setShareOpen}
            />
        }
        {openSignup &&
            <ModalSignUp
                setOpen={setOpenSignup}
                setOpenLogin={setLoginOpen}
            />
        }
        {loginOpen && 
            <ModalLogin 
                setOpen={setLoginOpen}
                setOpenSignup={setOpenSignup}
            />
        }

        <ProfileHeader />
        <div className='profile-container'>
            <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'baseline', flexWrap:'wrap'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <button style={{background: 'unset', border: 'unset'}} onClick={() => navigate('/explore?tab=2')}>
                        <img style={{height: 30, width: 30 , marginRight: 20}} src={back} alt='Om Yoga back button'/>
                    </button>
                    <h1 style={{color: '#313131'}}>{info.studioName}</h1>
                </div>
                <div style={{display:'flex'}}>
                    <div className='hover' style={{display:'flex', alignItems: 'baseline', marginRight: 20}} onClick={() => setShareOpen(true)}>
                        <img style={{height: 22, width: 22, marginRight: 10}} src={share} alt='Share Om Yoga teacher profile'/>
                        <p>Share</p>
                    </div>
                    
                    <div className='hover' style={{display:'flex', alignItems: 'baseline'}} onClick={handleFollow}>
                        <img style={{height: 25, width: 25, marginRight: 10}} src={follow} alt='Follow Om Yoga teacher profile'/>
                        <p>Follow</p>
                    </div>
                </div>
            </div>

            <div style={{display:'flex', flexWrap:'wrap'}}>

                <div className='profile-left'>
                    <img className='profile-logo' src={info.logo}/>
                    
                      <div className='profile-links'>
                        <div>
                            {info.website && 
                            <a href={info.website} target='_blank' style={{display: 'flex', alignItems: 'center'}}>
                                <img style={{width: 40, height: 40, marginRight: 10}} src={website}/>
                                <p style={{textDecoration: 'underline'}}>Visit website</p>
                            </a>
                            }
                        </div>

                        <div>
                            <a>
                                <img style={{width: 40, height: 40, margin: 5}} src={fb} alt='Om Yoga social media link'/>
                            </a>
                            <a>
                                <img style={{width: 40, height: 40, margin: 5}} src={insta} alt='Om Yoga social media link'/>
                            </a>
                        </div>

                    </div>

                    <div className='divider' />
                    <textarea ref={textareaRef} className='profile-bio' value={info.bio}>
                    </textarea>
                </div>


                <div className='profile-right'>
                    <h3 style={{marginTop: 0, color: '#313131'}}>Upcoming Events</h3>

                    {events.length < 1 ?
                    <p>No events</p>

                    :
                    events.map((event, i) => {
                        return (
                            <div className='profile-event'>
                                <div className='class-date-sml'>
                                    <div className='class-month-sml'>
                                        <p style={{fontSize: 14,color:'white', margin: 0}}>{moment(event.start).format('MMMM')}</p>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding: 5}}>
                                        <p style={{fontSize: 18, margin: 0}}>{moment(event.start).format('DD')}</p>
                                        <p style={{color: '#a28f89', fontWeight: 'bold', margin: 0, fontSize: 12}}>{moment(event.start).format('dddd')}</p>
                                    </div>

                                </div>
                                <div style={{width: '50%'}}>
                                    <p style={{fontSize: 20, marginBottom: 5}}>{event.className}</p>
                                    <p style={{color: '#717171', margin: 0, fontSize: 14}}>{event.venueName}</p>
                                    <p style={{color: '#717171', margin: 0, fontSize: 14}}>{moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}</p>

                                </div>
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <button className='om-btn'>Book Now</button>
                                    {event.googleMaps && 
                                    <a href={event.googleMaps} style={{textDecoration:'none'}} target='_blank'>
                                        <button className='map-btn'>
                                            <img style={{height: 25, width: 25}} src={map} alt='Om Yoga booking app link to google maps'/>
                                            <p style={{color:'white'}}>View on Map</p>
                                        </button>
                                    </a>
                                    }
                                </div>
                            </div>
                        )
                    })
                    }
                </div>

            </div>

            
        </div>
        <Footer/>
        
    </div>
  )
}
