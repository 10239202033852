import React, { useState} from 'react'
import './Header.css'
import { Link, useLocation } from 'react-router-dom';
import logo from '../../Assets/coloured.webp'
import Sidenav from './Sidenav';
import { useAuth } from '../../Context/AuthContext'
import {  signOut } from "firebase/auth";
// firebase
import { auth } from '../../Firebase'
import ModalLogin from '../PublicUI/Modals/ModalLogin'
import DropDownMenu from '../PublicUI/DropDownMenu';

export default function Header() {

  let location = useLocation()
  let { currentUser} = useAuth()
  const [showPopover, setShowPopover] = useState(false)
  const [openLogin, setOpenLogin] = useState(false)

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
        // Sign-out successful.
        window.location.reload()
    }).catch((error) => {
        // An error happened.
    });
}
  return (
    <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0.7rem 5%'}}>

      {openLogin && <ModalLogin setOpen={setOpenLogin}/>}

      <nav style={{ display: 'flex', alignItems: 'center'}}>
        <Link to={'/'} style={{display:'flex', textDecoration: 'none', alignItems:'center'}}>
        {location.pathname !== '/' && <img style={{height: 48, width: 48, marginRight: '1rem'}} src={logo} />}
        </Link>

        {/*<Sidenav />*/}

        {location.pathname === '/studio' &&
        <div className='mobile-remove' id='studio-desktop-nav'>
          <a className='landing-nav-item' onClick={() => handleClickScroll('features')}>Features</a>
          <a className='landing-nav-item' onClick={() => handleClickScroll('pricing')}>Pricing</a>
          <a className='landing-nav-item' onClick={() => handleClickScroll('faqs')}>Support</a>

        </div>
        }
      </nav>

      <div style={{display:'flex'}}>
        <div className='mobile-remove' style={{ display: 'flex', alignItems: 'center'}}>
          <p style={{color: '#313131', fontSize: 14}}>Want to find classes and studios near you?</p>
          <Link to={'/explore'}>
              <button id='explore-btn'>Explore</button>
          </Link>
        </div>

        <DropDownMenu />

        </div>
    </header>
  )
}
