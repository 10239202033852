import React, { useEffect, useState} from 'react'
import './Classes.css'
import Modal from 'react-modal';
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'
import { toast } from 'react-toastify'

Modal.setAppElement('#root');

export default function Classes() {

  let { currentUser } = useAuth()

  useEffect(() => {
    getClasses()
  }, [])

  const [classes, setClasses] = useState([])
  const [className, setClassName] = useState("")
  const [venueName, setVenueName] = useState("")
  const [venueAddress, setVenueAddress] = useState("")
  const [googleMaps, setGoogleMaps] = useState("")
  const [price, setPrice] = useState("")
  const [spaces, setSpaces] = useState("")
  const [cancellation, setCancellation] = useState("")
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")
  const [modalIsOpen, setIsOpen] = useState(false);
  const [classOpen, setClassOpen] = useState(false);
  const [classId, setClassId] = useState("");
  const [classType, setClassType] = useState("");
  const [bookingLink, setBookingLink] = useState("");


  function openModal(type) {
    setClassType(type)
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openClassModal() {
    setClassOpen(true);
  }

  function closeClassModal() {
    setClassOpen(false);
    setClassName("");setVenueName("");setVenueAddress("");
    setGoogleMaps("");setPrice("");setSpaces("");setStartTime("");
    setEndTime("");setClassId("");setCancellation("");
    setBookingLink("");setClassType("")
  }

  const getClasses = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/classes/get-classes-by-teacher`, {teacherId: currentUser.uid})
    .then((res) => {
      setClasses(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const createClass = () =>{
    if(classType === 'Independent' && (!className || !venueName || !price || !spaces || !startTime || !endTime || !cancellation)) {
      toast.error("Please add all class information")
    } else if (classType === 'Studio' && (!className || !venueName || !price || !startTime || !endTime )){
      toast.error("Please add all class information")

    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/classes/create-new-class`, {
        teacherId: currentUser.uid,
        className,
        venueName,
        venueAddress,
        googleMaps,
        price,
        spaces,
        startTime,
        endTime,
        cancellation,
        classType,
        bookingLink
      })
      .then(() => {
        toast.success("Created successfully!")
        setClassName("");setVenueName("");setVenueAddress("");
        setGoogleMaps("");setPrice("");setSpaces("");setStartTime("");
        setEndTime("");setCancellation("");setBookingLink("");setClassType("")
        closeModal()
        getClasses()
      })
      .catch((e) => {
        console.log(e)
      })
    }
 
  }

  const updateClass = () =>{
    if(classType === "Independent" && !className || !venueName || !price || !spaces || !startTime || !endTime || !cancellation) {
      toast.error("Please add all class information")
    } else if(classType === "Studio" && !className || !venueName || !price || !startTime || !endTime){
      toast.error("Please add all class information")
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/classes/update-class`, {
        classId,
        className,
        venueName,
        venueAddress,
        googleMaps,
        price,
        spaces,
        startTime,
        endTime,
        cancellation,
        bookingLink,
      })
      .then(() => {
        toast.success("Updated successfully!")
        closeClassModal()
        getClasses()
      })
      .catch((e) => {
        console.log(e)
      })
    }
 
  }

  const handleClassClick = (classInfo) => {
    openClassModal()
    setClassName(classInfo.className)
    setVenueName(classInfo.venueName)
    setVenueAddress(classInfo.venueAddress)
    setStartTime(classInfo.startTime)
    setEndTime(classInfo.endTime)
    setGoogleMaps(classInfo.googleMaps)
    setPrice(classInfo.price)
    setSpaces(classInfo.spaces)
    setClassId(classInfo._id)
    setCancellation(classInfo.cancellation)
    setBookingLink(classInfo.bookingLink)
    setClassType(classInfo.classType)
  }

  return (
    <div className='outlet'>
        <button className='om-btn' style={{margin:'1rem auto'}} onClick={() => openModal("Independent")}>Add independent class</button>
        <button className='om-btn' style={{margin:'1rem 10px'}} onClick={() => openModal("Studio")}>Add studio class</button>


        <table id='classes-table'>
            <tr>
                <th>Class name</th>
                <th>Venue name</th>
                <th>Class price</th>
                <th>Class type</th>
            </tr>

            {classes.length < 1 
            ?
            <tr >
                <td style={{textAlign:'center', padding: 30}} colSpan={4}>No classes added</td>
            </tr>
            : 
            classes.map((myclass, i) => {
              return (
              <tr key={i} onClick={() => handleClassClick(myclass)}>
                <td>{myclass.className}</td>
                <td>{myclass.venueName}</td>
                <td>£{myclass.price}</td>
                <td>{myclass.classType}</td>
              </tr>
              )

            })
            }   
         
            
        </table>

        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{...customStyles, overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(70, 70, 70, 0.5)',
        }}}
        contentLabel="Example Modal"
      >

          <input className='classes-input' placeholder='Class name eg. Ashtanga' value={className} onChange={(e) => setClassName(e.target.value)}/>
          <label>Start time</label>
          <input className='classes-input' type='time' value={startTime} onChange={(e) => setStartTime(e.target.value)}/>
          <label>End time</label>
          <input className='classes-input' type='time' value={endTime} onChange={(e) => setEndTime(e.target.value)}/>
          <input className='classes-input' placeholder='Venue name' value={venueName} onChange={(e) => setVenueName(e.target.value)}/>
          <input className='classes-input' placeholder='Venue address' value={venueAddress} onChange={(e) => setVenueAddress(e.target.value)}/>
          <input className='classes-input' placeholder='Google map link' value={googleMaps} onChange={(e) => setGoogleMaps(e.target.value)}/>
          <div className='classes-input'>
            <input className='price-input' type='number' placeholder='Class price per person' value={price} onChange={(e) => setPrice(e.target.value)}/>
            <span>£</span>
          </div>
         {classType=== 'Independent' 
          &&
          <>
            <input className='classes-input' type='number' placeholder='Number of spaces' value={spaces} onChange={(e) => setSpaces(e.target.value)}/>
            <input className='classes-input' type='number' placeholder='Cancellation period (hours)' value={cancellation} onChange={(e) => setCancellation(e.target.value)}/>
          </>
         }
          {classType=== 'Studio' 
          &&
          <>
            <input className='classes-input' type='text' placeholder='Link to external booking site' value={bookingLink} onChange={(e) => setBookingLink(e.target.value)}/>
          </>
         }

          <div >
            <button onClick={createClass} style={{marginRight: 10}} className='om-btn'>Create class</button>
            <button className='modal-close-btn' onClick={closeModal}>close</button>     
          </div>
    
      </Modal>

        {/*View class*/}
      <Modal
        isOpen={classOpen}
        onRequestClose={closeClassModal}
        style={{...customStyles, overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(70, 70, 70, 0.5)',
        }}}
        contentLabel="Example Modal"
      >

          <input className='classes-input' placeholder='Class name eg. Ashtanga' value={className} onChange={(e) => setClassName(e.target.value)}/>
          <label>Start time</label>
          <input className='classes-input' type='time' value={startTime} onChange={(e) => setStartTime(e.target.value)}/>
          <label>End time</label>
          <input className='classes-input' type='time' value={endTime} onChange={(e) => setEndTime(e.target.value)}/>
          <label>Venue</label>
          <input className='classes-input' placeholder='Venue name' value={venueName} onChange={(e) => setVenueName(e.target.value)}/>
          <label>Address</label>
          <input className='classes-input' placeholder='Venue address' value={venueAddress} onChange={(e) => setVenueAddress(e.target.value)}/>
          <label>Google maps link</label>
          <input className='classes-input' placeholder='Google map link' value={googleMaps} onChange={(e) => setGoogleMaps(e.target.value)}/>
          <label>Price</label>
          <div className='classes-input'>
            <input className='price-input' type='number' placeholder='Class price per person' value={price} onChange={(e) => setPrice(e.target.value)}/>
            <span>£</span>
          </div>
          {classType=== 'Independent' 
          &&
          <>
            <label>Number of spaces</label>
            <input className='classes-input' type='number' placeholder='Number of spaces' value={spaces} onChange={(e) => setSpaces(e.target.value)}/>
            <label>Cancellation period (number of hours before class)</label>
            <input className='classes-input' type='number' placeholder='Cancellation period (hours)' value={cancellation} onChange={(e) => setCancellation(e.target.value)}/>
          </>
         }
          {classType=== 'Studio' 
          &&
          <>
            <label>Link to external booking site</label>
            <input className='classes-input' type='text' placeholder='Link to external booking site' value={bookingLink} onChange={(e) => setBookingLink(e.target.value)}/>
          </>
         }
          <div >
            <button onClick={updateClass} style={{marginRight: 10}} className='om-btn'>Update class</button>
            <button className='modal-close-btn' onClick={closeClassModal}>close</button>     
          </div>
    
      </Modal>
    </div>
  )
}
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      display:'flex',
      flexDirection:'column',
      width: 600,
      maxWidth: '95vw',
      maxHeight: '90vh',
      overflow:'scroll'
    },
  };