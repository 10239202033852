
import React, { useState } from 'react'
import './Modal.css'
import close from '../../../Assets/close.png'
import { toast } from 'react-toastify'
import { signInWithEmailAndPassword} from "firebase/auth";
import { auth } from '../../../Firebase'

export default function ModalLogin({setOpen, setOpenSignup}) {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents click event from bubbling up to the modal div
  };

  const handleLogin = () => {
    if(!email || !password){
      toast.error("Please add email and password")
    } else {
      setLoading(true)
      signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        // Signed in
        setOpen(false)
        setLoading(false)
        //Refresh page to get user
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false)
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
      });
    }

  }

  const handleSwitch = () => {
    setOpen(false)
    setOpenSignup(true)
  }

  return (
    <div className='modal' onClick={(e) =>  setOpen(false)}>

        <div className='modal-container' onClick={handleContainerClick}>
          <img className='close-modal-btn hover' src={close} alt='Close Om Yoga share box' onClick={() => setOpen(false)}/>

          <h3 className='modal-header'>Login</h3>

          <input className='top-input' placeholder='Email address' type='text' value={email} onChange={(e) => setEmail(e.target.value)}/>
          <input className='bottom-input' placeholder='Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>

          <button className='modal-btn' onClick={handleLogin} disabled={loading}>
            {loading ? 'Logging in...' : "Login"}
          </button>

          <p>Don't have an account? <span className='hover' style={{textDecoration: 'underline'}} onClick={handleSwitch}>Create one for free</span></p>

        </div>
        
    </div>
  )
}
