import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import logo from '../../Assets/om.png'
import apple from '../../Assets/apple-icon.png'
import android from '../../Assets/google-icon.png'

export default function Footer() {
  return (
    <footer>
        <Link to={'/'}>
          <img id='footer-logo'  src={logo} alt='Om Yoga logo for booking yoga classes'/>
          <p style={{fontFamily: 'Arual', margin: '5px 0'}}>Om Yoga</p>
        </Link>

        <div id='footer-app-links'>
          <a  href='https://apps.apple.com/uk/app/om-yoga/id6470202583' target='_blank'>
            <img className='footer-app'  src={apple} alt='Om Yoga App store link for booking yoga classes'/>
          </a>
          <a  href='https://play.google.com/store/apps/details?id=com.omyoga.omyoga&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank'>
            <img className='footer-app'  src={android} alt='Om Yoga App store link for booking yoga classes'/>
          </a>

        </div>
        <div style={{display:'flex', flexDirection: 'column'}}>
            <Link to={'/blogs'}>Blog</Link>
            <Link to={'/terms'}>Terms & Conditions</Link>
            <a href='https://www.freeprivacypolicy.com/live/a4450326-98cb-4e11-bf40-5be3b32046ea' target='_blank'>Privacy Policy</a>
        </div>
        

    </footer>
  )
}
