import React, { useState } from 'react'
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { toast } from 'react-toastify';
import axios from 'axios'
import './ClassPaymentForm.css'
import { useAuth } from '../../../Context/AuthContext'


const ClassPaymentForm = ({ onSuccess, student, event, connectedAccountId, setShowPaymentForm }) => {
    let { role } = useAuth()

    const stripe = useStripe()
    const elements = useElements()
    const [isProcessing, setIsProcessing] = useState(false);

    const handlePaymentSubmit = (subscriptionScheduleId) => {
      // successful payment - return to parent
      const paymentData = {
          message: 'Payment Successfully Submitted',
          subscriptionScheduleId: subscriptionScheduleId,
      }

      // Call the onSuccess function passed from the parent
      onSuccess(paymentData);
    };

    const fetchSetupIntentClientSecret = async (cardFieldPaymentMethodId) => {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/payments/create-customer-setup-intent`,
            {
                userId: student.uid,
                userRole: role,
                name: student.displayName,
                email: student.email,
                payment_method_id: cardFieldPaymentMethodId,
                eventId: event._id.toString(),
                description: "Yoga Class (client booking)"
            }
        );

        const { clientSecret, customerId, paymentMethodId, newPaymentMethod, error } = response.data;
        return { clientSecret, customerId, paymentMethodId, newPaymentMethod, error };
    };


    const createScheduledPayment = async (customerId, paymentMethodId, newPaymentMethod) => {

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/payments/create-scheduled-payment`,
            {
                payment_method_id: paymentMethodId,
                newPaymentMethod,
                amount: event.price,
                start_time: event.start,
                connectedAccountId,
                event_id: event._id,
                customerId
            }
        );

         const { subscriptionScheduleId, error } = response.data;
         return { subscriptionScheduleId, error };
     };

    // payments are created as schedules for the time of the class
    // server handles that. Client here handles card validation and
    // payment confirmation
    const handlePayment = async () => {
        setIsProcessing(true);
        console.log("Handle Payment")

        try {
            const { paymentMethod, error: pmError } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
                // for inclusion consider retention of info (e.g. GDPR) vs security (e.g. proof of id)
                // note that FDM client payment includes email but om mobile excludes
                /* billing_details: {
                    email: cardEmail,
                    name: user.displayName,
                }, */
            })

            if (pmError) {
                setIsProcessing(false)
                toast.error(pmError.message)
                return;
            }

            // Notes on checking card country:
            // Client side checks are subject to spoofing but:
            //   - worst case from app perspective a foreign card is used and it incurs a higher stripe fee
            //   - worst case from user perspective, they prevent themselves from being able to book
            // However, UX is affected. A user who might have expected to be able to use the service with
            // their foreign card cannot. This should be present in terms and conditions and should be rare
            if (paymentMethod?.card?.country !== 'GB') {
                setIsProcessing(false)
                console.log("Can't process non UK cards", paymentMethod?.card?.country)
                toast.error("Apologies. Payments are only accepted from UK issued cards.")
                return;
            }

            let { clientSecret, customerId, paymentMethodId, newPaymentMethod, error } =
                await fetchSetupIntentClientSecret(paymentMethod.id);

            /* as above, consider whether these are essential identification or a security risk */
            const billingDetails = { /* name: cardName, email: cardEmail */ };

            // confirm the payment
            if (error) {
                setIsProcessing(false)
                console.log("Problem while setting up payment:", error);
                toast.error("Problem while setting up payment")
            } else {
                let setupIntent
                if (clientSecret) {
                    let csError
                    ({ setupIntent, error: csError } = await stripe.confirmSetup({
                        clientSecret,
                        redirect: 'if_required',
                        confirmParams: {
                            return_url: process.env.REACT_APP_CLIENT_URL,
                            payment_method_data: {
                                billing_details: billingDetails
                            }
                        }
                    }))

                    if (csError) {
                        setIsProcessing(false)
                        console.log("Problem confirming setup intent:", csError)
                        toast.error(`Payment Issue: ${csError.message}`)
                        return
                    } else if (setupIntent) {
                        console.log("Setup intent confirmed", setupIntent)
                    }
                }

                const { subscriptionScheduleId, error: spError } =
                    await createScheduledPayment(
                        customerId,
                        newPaymentMethod ? setupIntent.payment_method : paymentMethodId,
                        newPaymentMethod
                    );

                if (spError) {
                    setIsProcessing(false)
                    console.log("Problem setting up schedule:", spError);
                    toast.error("Unable to complete payment")
                    return
                    // a failure at this point means that the schedule did not get set up and a
                    // successfully set up schedule is the only means by which a payment can
                    // actually be taken. Therefore failures should not result in erroneous payments
                }

                setIsProcessing(false)
                handlePaymentSubmit(subscriptionScheduleId);
            }

            setIsProcessing(false);

        } catch (error) {
            console.error('Error creating payment:', error);
            toast.error("Error creating payment")
            setIsProcessing(false);
        }
    };

    return (
        <div className="card-element-container">
            <div className="card-element full-width">
                <CardNumberElement />
            </div>
            <div className="card-element narrow-width">
                <CardExpiryElement />
            </div>
            <div className="card-element narrow-width">
                <CardCvcElement />
            </div>
            <div>
        </div>
        <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between', width: '100%'}}>
            <button
                className='om-btn'
                style={{width: '45%', marginTop: 20}}
                onClick={handlePayment}
                disabled={isProcessing}
            >
                {isProcessing ? 'Processing...' : `Pay £${event.price}`}
            </button>

            <button className='cancel-btn' onClick={() => setShowPaymentForm(false)}>
                Cancel
            </button>
        </div>
        </div>
  )
}

export default ClassPaymentForm
