import React, { useState } from 'react'
import axios from 'axios'
import { useAuth } from '../Context/AuthContext'
import chatIcon from '../Assets/customer-support.png'
import './SupportChat.css'

const SupportChat = ({ children }) => {
    let { currentUser, role } = useAuth()

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [messageSent, setMessageSent] = useState(false)

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const handleNameChange = (event) => {
        setName(event.target.value)
    }

    const handleIconClick = () => {
        setIsDialogOpen(!isDialogOpen)
        if (!isDialogOpen) {
            setMessageSent(false)
        }
    }

    const handleCloseDialog = () => {
        setIsDialogOpen(false)
        if (!isDialogOpen) {
            setMessageSent(false)
        }
    }

    const handleSendMessage = () => {
        setMessageSent(true)

        // If a user is not logged in there will be
        // no currentUser so email must be entered
        // id will be generated backend
        let payload = {
            senderId: currentUser ? currentUser.uid : '',
            senderName: currentUser ? currentUser.displayName : name,
            senderEmail: currentUser ? currentUser.email : email,
            // senderImg: userInfo?.logo, TBD needs retrieval of user info
            senderType: role || 'Unregistered',
            message
        }

        axios.post(`${process.env.REACT_APP_API_URL}/chats/open-support-chat`, payload)
        .then((res) => {
            console.log(res.data)
            // clear the fields after message sent
            setMessage('')
            setEmail('')
            setName('')
        })
        .catch((error) => {
            console.log(error)
        })
    };

    return (
        <div className="supportchat-container">
            <div className="icon-container">
                <button className="icon-btn" onClick={handleIconClick}>
                    <img src={chatIcon} alt="Icon" />
                </button>
            </div>

            {isDialogOpen && (
                <div className="dialog-box">
                    <div className="dialog-box">
                        <div className="dialog-content">
                            <span className="dialog-title" onClick={handleCloseDialog}>How Can We Help?</span>
                            <span className="close-btn" onClick={handleCloseDialog}>&times;</span>

                            <div className="dialog-text">
                                Online chat is not yet available but send<br />
                                us a message and we'll get back to you.
                            </div>

                            {currentUser ? (
                                <input
                                    type="text"
                                    value={currentUser.displayName}
                                    readOnly
                                    className="name-input"
                                />
                            ) : (
                                <input
                                    type="text"
                                    value={name}
                                    onChange={handleNameChange}
                                    placeholder="Full name"
                                    className="name-input"
                                />
                            )}

                            {currentUser ? (
                                <input
                                    type="email"
                                    value={currentUser.email}
                                    readOnly
                                    className="email-input"
                                />
                            ) : (
                                <input
                                    type="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    placeholder="Enter your email"
                                    className="email-input"
                                />
                            )}

                            <div>
                                <textarea
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder="Enter your message"
                                    className="message-input"
                                />
                            </div>

                            {messageSent ?
                                <div className="dialog-text">
                                    Your message has been sent.<br />
                                </div>
                            :
                                <button
                                    style={{
                                        backgroundColor: !message.trim() ? '#ccc' : null,
                                        cursor: !message.trim() ? 'not-allowed' : null,
                                        opacity: !message.trim() ? 0.6 : null
                                    }}
                                    title={!message.trim() ? 'Type a message' : ''}
                                    onClick={handleSendMessage}
                                    disabled={!message.trim() || messageSent}
                                >
                                    Send
                                </button>
                            }
                        </div>
                    </div>
                </div>
            )}
            {children}
        </div>
    );
};

export default SupportChat
