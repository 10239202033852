import React, { useState, useEffect} from 'react'
import './Explore.css'
import logo from '../../Assets/om.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ExploreTeachers from './ExploreTeachers'
import ExploreStudios from './ExploreStudios'
import ExploreClasses from './ExploreClasses'
import Footer from '../Landing/Footer'
import DropDownMenu from './DropDownMenu'

export default function Explore() {

    // Set state with a default tab number or retrieve from URL query parameter
    const [tab, setTab] = useState(() => {
        const queryParams = new URLSearchParams(window.location.search);
        return parseInt(queryParams.get('tab'), 10) || 1;
    });
    const [teachers, setTeachers] = useState([])
    const [filtered, setFiltered] = useState([])
    const [loading, setLoading] = useState(true)
    const [studios, setStudios] = useState([])
    const [classes, setClasses] = useState([])

    const location = useLocation();
    const navigate = useNavigate();

    // Update URL query parameter whenever activeTab changes
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('tab', tab);
        navigate({ search: queryParams.toString() });
    }, [tab, location.search, navigate]);


    useEffect(() => {
        if(tab === 1){
            getTeachers()
        } else if(tab === 2){
            getStudios()
        } else if( tab === 3){
            getClasses()
        }

    }, [tab])

    const getTeachers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/teachers/get-teachers`)
        .then((res) => {
            setTeachers(res.data)
            setFiltered(res.data)
            setLoading(false)
        })
        .catch((e) => {
            console.log(e)
            setLoading(false)
        })
    }

    const getStudios = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/studios/get-studios`)
        .then((res) => {
            console.log(res.data)
            setStudios(res.data)
            setLoading(false)

        })
        .catch((e) => {
            console.log(e)
            setLoading(false)

        })
      }

    const getClasses = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/events/get-all-future-events`)
        .then((res) => {
            console.log(res.data)
            setClasses(res.data)
            setLoading(false)

        })
        .catch((e) => {
            console.log(e)
            setLoading(false)

        })
    }

    const changeTab = (tab) => {
        setLoading(true)
        setTab(tab)
    }


  return (
    <div>
        <header id='explore-header'>
            <Link to={'/'} style={{display:'flex', alignItems:'center', textDecoration:'none'}}>
                <img style={{width: 50, height: 50}} src={logo} alt='Om Yoga booking app logo' />
                <p className='mobile-remove' style={{fontFamily:'Arual', margin: '0 10px', fontSize: 28}}>Om Yoga</p>
            </Link>

            <div id='explore-nav' className='mobile-remove'>
                <p className={tab === 1 ? 'explore-nav-item-active' : 'explore-nav-item'} onClick={() => changeTab(1)}>Teachers</p>
                <p className={tab === 2 ? 'explore-nav-item-active' : 'explore-nav-item'} onClick={() => changeTab(2)}>Studios</p>
                <p className={tab === 3 ? 'explore-nav-item-active' : 'explore-nav-item'} onClick={() => changeTab(3)}>Classes</p>
            </div>


            <DropDownMenu />

            <div id='explore-nav-mobile'>
                <p className={tab === 1 ? 'explore-nav-mobile-item-active' : 'explore-nav-mobile-item'} onClick={() => changeTab(1)}>Teachers</p>
                <p className={tab === 2 ? 'explore-nav-mobile-item-active' : 'explore-nav-mobile-item'} onClick={() => changeTab(2)}>Studios</p>
                <p className={tab === 3 ? 'explore-nav-mobile-item-active' : 'explore-nav-mobile-item'} onClick={() => changeTab(3)}>Classes</p>
            </div>
        </header>

        {tab === 1 && <ExploreTeachers teachers={teachers} loading={loading}/>}
        {tab === 2 && <ExploreStudios studios={studios} loading={loading}/>}
        {tab === 3 && <ExploreClasses classes={classes} getClasses={getClasses} loading={loading}/>}

        <Footer />
    </div>
  )
}
