import React from 'react'

export default function Terms() {
  return (
    <div style={{width: 1000, maxWidth: '95vw', margin: '2rem auto'}}>
        <h2>Terms and Conditions for Om Yoga App</h2>

        <h3>1. Acceptance of Terms</h3>

        <p>By using the Om Yoga mobile app ("the App") provided by Om Yoga Ltd ("the Company"), you agree to comply with and be bound by the following terms and conditions ("T&C"). If you do not agree to these T&C, please do not use the App.</p>

        <h3>2. Account Registration</h3>

        <p>a. Teachers: To utilize the App's features, teachers must register and create an account. Teachers are responsible for providing accurate information and maintaining the security of their account credentials.</p>

        <p>b. Students: Students must register and create an account to book classes through the App.</p>

        <h3>3. Class Booking and Payment</h3>

        <p>a. Class Booking: Teachers can add classes to the App, and students can book these classes through the App.</p>

        <p>b. Payment Options: Students have the option to pay for classes through the App or choose to pay in cash at the class venue.</p>

        <p>c. Payment Processing: The App utilizes Stripe for payment processing. By using the App, you agree to Stripe's terms and conditions.</p>

        <p>d. Payment Transfer: Payments made through the App go directly from the student to the teacher's bank account through the Stripe payment gateway.</p>

        <h3>4. Pre-recorded Classes and Tutorial Videos</h3>

        <p>a. Disclaimer: Users acknowledge that pre-recorded classes and tutorial videos provided on the App are for informational and educational purposes only.</p>

        <p>b. No Liability: The Company holds no liability for any harm or injuries caused by following the instructions in pre-recorded classes or tutorial videos. Users participate at their own risk, and it is recommended to consult with a healthcare professional before starting any new exercise program.</p>

        <h3>5. Loss of Earnings - Teachers</h3>

        <p>a. Loss of Earnings: Om Yoga Ltd shall not be liable for any loss of earnings incurred by teachers as a result of using the App. Teachers understand and agree that the App is a platform for class management and booking, and the Company does not guarantee a minimum number of bookings or income.</p>

        <p>b. Independent Contractor Relationship: Teachers using the App acknowledge that they are independent contractors, and the Company is not responsible for any financial or business-related aspects beyond the functionality of the App.</p>

        <p>c. No Employment Relationship: The use of the App does not create an employment relationship between teachers and Om Yoga Ltd. Teachers are responsible for managing their own classes, schedules, and earnings.</p>

        <h3>6. App Uptime, Maintenance, and Updates</h3>

        <p>a. Uptime Guarantee: The Company strives to maintain a minimum uptime for the App to ensure uninterrupted access. However, the Company does not guarantee continuous, error-free, or secure access to the App and may temporarily suspend services for maintenance or other reasons.</p>

        <p>b. Maintenance and Updates: The Company reserves the right to conduct scheduled maintenance and updates to improve the App's functionality and security. Users will be notified in advance whenever possible, and the Company shall aim to minimize disruptions.</p>

        <h3>7. User Conduct</h3>

        <p>a. Compliance: Users must comply with all applicable laws and regulations while using the App.</p>

        <p>b. Prohibited Activities: Users are prohibited from engaging in any activities that may harm the App, its users, or third parties.</p>

        <h3>8. Privacy and Data Security</h3>

        <p>a. Privacy Policy: The App's privacy policy outlines how user data is collected, used, and protected. By using the App, you agree to the terms outlined in the privacy policy.</p>

        <p>b. Data Security: The Company employs industry-standard measures to protect user data; however, the Company cannot guarantee absolute security.</p>

        <h3>9. Termination of Accounts</h3>

        <p>a. Termination by Users: Users can terminate their accounts at any time by following the App's account termination procedures.</p>

        <p>b. Termination by the Company: The Company reserves the right to terminate or suspend user accounts for violations of these T&C.</p>

        <h3>10. Modification of Terms</h3>

        <p>The Company reserves the right to modify these T&C at any time. Users will be notified of any changes, and continued use of the App constitutes acceptance of the modified T&C.</p>

        <h3>11. Governing Law</h3>

        <p>These T&C are governed by the laws of the United Kingdom. Any disputes arising from the use of the App shall be subject to the exclusive jurisdiction of the courts of the United Kingdom.</p>

        <h3>12. Severability</h3>

        <p>If any provision of these Terms and Conditions is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</p>

        <h3>13. Contact Information</h3>

        <p>If you have any questions or concerns about these T&C, please contact Om Yoga Ltd at support@om-yoga.co.uk</p>

        <p>By using the Om Yoga mobile app, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions, including provisions related to app uptime, maintenance, and updates.</p>
    </div>
  )
}
