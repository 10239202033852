import React from 'react'
import './StudentPage.css'
import Footer from './Footer'
import Header from './Header'
import circle from '../../Assets/student-circle.webp'
import mobile from '../../Assets/mobile.webp'
//App icons
import apple from '../../Assets/apple-black.webp'
import google from '../../Assets/google-black.webp'
//Icons
import payments from '../../Assets/payments-gold.webp'
import flexible from '../../Assets/flexible-gold.webp'
import community from '../../Assets/connection-gold.webp'
import media from '../../Assets/library.webp'
//Carousel
import Carousel from 'react-multi-carousel';

const features = [
  {feature: 'Flexibility in Practice', image: flexible, description: 'Embrace the freedom to practice yoga anytime anywhere, and search for classes that align with your schedule.'},
  {feature: 'Teacher Connection', image: community, description: 'Connect with your favourite yoga teachers and access their class schedules with just a few taps on our app.'},
  {feature: 'Instant and Secure Payments', image: payments, description: 'Enjoy the convenience of instant and secure payments, making it easy to book and join classes.'},
  {feature: 'Curated Exercise Library', image: media, description: 'Access a growing library of poses and breathing exercises, empowering you to harmonise your practice with your lifestyle.'},

]

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 748 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 748, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
export default function StudentPage() {
  return (
    <>
      <Header />
      <h2 id='student-mobile-header'>Make your yoga practice <span style={{color:'#A28F89'}}>flexible around you.</span></h2>


      <div id='student-hero'>
        <div id='student-hero-info'>
          <h2 style={{fontSize: 42, fontWeight: 200, margin: 0}}>Make your yoga practice flexible around you.</h2>
          <p style={{fontSize: 20}}>Om Yoga is the ultimate companion for integrating your yoga practice effortlessly into your busy life.</p>
          <div>
            <a href='https://apps.apple.com/uk/app/om-yoga/id6470202583' target='_blank'>
              <img style={{height: 52, marginRight: 10}} src={apple} alt='app store link to Om Yoga booking app for studios '/>
            </a>
            <a  href='https://play.google.com/store/apps/details?id=com.omyoga.omyoga&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank'>
              <img style={{height: 52}} src={google} alt='app store link to Om Yoga booking app for studios '/>
            </a>

          </div>
        </div>
      </div>

      <div id='mobile-hero-info'>
          <p style={{fontSize: 20, textAlign:'center'}}>Om Yoga is the ultimate companion for integrating your yoga practice effortlessly into your busy life.</p>
          <div style={{width: '90vw', textAlign:'center'}}>
            <a href='https://apps.apple.com/uk/app/om-yoga/id6470202583' target='_blank'>
              <img style={{height: 52, margin: 5}} src={apple} alt='app store link to Om Yoga booking app for studios '/>
            </a>
            <a  href='https://play.google.com/store/apps/details?id=com.omyoga.omyoga&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank'>
              <img style={{height: 52, margin: 5}} src={google} alt='app store link to Om Yoga booking app for studios '/>
            </a>

          </div>
        </div>

      <p style={{width: 1400, maxWidth: '90vw', color: '#A28F89', margin: '3rem auto', textAlign: 'center', fontSize: 18}}>
        {window.innerWidth < 1100 ? 
        "Simplify your yoga journey, with Om Yoga, an app to make practicing yoga easy and convenient." 
        : 
        "Om Yoga simplifies your yoga journey, connecting you with your local teachers, offering easy class bookings and secure instant payments. Enjoy flexibility with access to a growing library of exercise for convenient practice anywhere."
        }
      </p>

      <div id='student-features'> 
      {features.map((feature, i) => {
        return (
          <div className='student-features'  key={i}>
              <div style={{display:'flex', alignItems:'center', width: 600}}>
                <img style={{width: 80, height: 80, marginRight: 50, objectFit: 'contain'}} src={feature.image} alt='Om Yoga booking app for yoga teachers and studios'/>
      
                <div className='feature-txt'>
                  <h3 style={{fontSize: 28, fontWeight: 100, margin: '10px 0'}}>{feature.feature}</h3>
                  <p style={{fontSize: 18, color: '#313131'}}>{feature.description}</p>
                </div>
      
              </div>
              {i === 0 && <img id='student-phone' src={mobile}/>
              }

              {i === 3 && <img id='student-circle' src={circle}/>
              }

        </div>
        )
      })}
    </div>

    <div id='student-carousel'>
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
        {features.map((feature, i) => {
        return (
          <div className='mobile-feature'  key={i}>
                <img style={{width: 80, height: 80, marginRight: 50, objectFit: 'contain'}} src={feature.image} alt='Om Yoga booking app for yoga teachers and studios'/>
      
                <div>
                  <h3 style={{fontSize: 28, fontWeight: 100, margin: '10px 0'}}>{feature.feature}</h3>
                  <p style={{fontSize: 18, color: '#313131'}}>{feature.description}</p>
                </div>
          </div>
        )
      })}
    </Carousel>
    </div>
     
      <img id='mobile-student-phone' src={mobile}/>

      <div id='student-footer'>
        <div id='student-footer-info'>
          <h2 style={{fontSize: 40, fontWeight: 200, color:'#313131'}}>Embrace the restorative power of Yoga easily with Om Yoga</h2>
          <div style={window.innerWidth < 1100 ? {width: '90vw', textAlign:'center'} :{}}>
            <a href='https://apps.apple.com/uk/app/om-yoga/id6470202583' target='_blank'>
              <img style={{height: 52, margin: 5}} src={apple} alt='app store link to Om Yoga booking app for studios '/>
            </a>
            <a  href='https://play.google.com/store/apps/details?id=com.omyoga.omyoga&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank'>
              <img style={{height: 52, margin: 5}} src={google} alt='app store link to Om Yoga booking app for studios '/>
            </a>

          </div>
        </div>
      </div>

      <Footer />

    </>
  )
}
