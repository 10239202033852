import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import './StudioPage.css'
//Icons
import discover from '../../Assets/eye-gold.png'
import payments from '../../Assets/pricing-gold.png'
import messaging from '../../Assets/messaging.webp'
import desktop from '../../Assets/desktop.webp'
import community from '../../Assets/studio-community.png'
import connection from '../../Assets/connection-gold.webp'
import fingertips from '../../Assets/fingertips.png'
import calendar from '../../Assets/calendar-gold.png'
import map from '../../Assets/map.png'
import check from '../../Assets/check.webp'
import FAQ from './FAQ'
import Support from './Support'
import { PopupWidget, PopupModal } from "react-calendly";

//Carousel
import Carousel from 'react-multi-carousel';
import ContactModal from './ContactModal'

const features = [
  {feature: 'Effortless Timetables', image: null, mobileImg: calendar, description: 'Take control of your time and classes effortlessly. With Om Yoga, creating and sharing your studio timetables becomes a breeze.'},
  {feature: 'Finances At Your Fingertips', image: payments, mobileImg: payments, description: 'Easily manage your money with a space dedicated to viewing and organising all payments.'},
  {feature: 'Boost Your Visibility', image: discover, mobileImg: discover, description: 'Help potential students and teachers find your studio by joining the studio directory on the Om Yoga ensuring maximum visibility for your yoga classes.'},
  {feature: 'Streamline Messaging', image: messaging, mobileImg: payments, description: 'Experience seamless coordination with instructors, clients, and staff with a message function promoting a harmonious and stress-free business experience.'},
  {feature: 'Build Your Community', image: null, mobileImg: connection, description: 'Create and manage a database of reliable yoga teachers. Assign them to classes at the touch of a button.'},

]
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 748 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 748, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

export default function StudioPage() {

  const [modalOpen, setModalOpen] = useState(false)
  const [calendlyOpen, setCalendlyOpen] = useState(false)
  const [enquiryType, setEnquiryType] = useState(false)

  const handleClick = (enquiry) => {
      setEnquiryType(enquiry)
      setModalOpen(true)
  }

  return (
    <div>
      {modalOpen && <ContactModal enquiry={enquiryType} setEnquiryType={setEnquiryType} setModalOpen={setModalOpen}/> }
      <PopupWidget
        url="https://calendly.com/om-yoga/30min"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text="Book a Demo"
        textColor="#ffffff"
        color="#A28F89"
      />
      <PopupModal
          url="https://calendly.com/om-yoga/30min"
          onModalClose={() => setCalendlyOpen(false)}
          open={calendlyOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
        />
      <Header />
      <h2 id='studio-mobile-header'>Make running your business as easy as <span style={{color: '#A28F89'}}>om, two, three.</span></h2>

      <div id='studio-hero'>
        <div id='studio-hero-info'>
          <h2 style={{fontSize: 42, fontWeight: 200, margin: 0}}>Make running your business as easy as om, two, three.</h2>
          <p style={{fontSize: 20}}>Streamline your yoga studio with Om Yoga, a complete platform for business management, bringing tranquility to your studio.</p>
          <button className='cta-btn' onClick={() => setCalendlyOpen(true)}>Book a Demo</button>
        </div>
      </div>
      <div className='desktop-remove' style={{width: '90vw', textAlign:'center', margin: '0 auto'}}>
        <p style={{fontSize: 20, color:'#A28F89', width: '100%'}}>Streamline your yoga studio with Om Yoga, a complete platform for business management, bringing tranquility to your studio.</p>
        <button className='cta-btn' onClick={() => setCalendlyOpen(true)}>Book a Demo</button>
      </div>
     

      {/*Features section */}
      <div id='features'>

        <h2 style={{fontSize: 32, fontWeight: 100, marginBottom: 5, width: '90vw', textAlign:'center'}}>See how Om Yoga works for your business</h2>
        <p style={{fontSize: 22, maxWidth: '80vw', width: '90vw', marginBottom: 50, textAlign:'center'}}>Manage your studio schedule, finances, communication and community all in one place.</p>
       
        <div id='studio-features'>
        {features.map((feature, i) => {
          return (
            <div style={{ position: 'relative', display: 'flex', alignItems:'center'}} key={i}>
              <div className={(i === 0 || i === 4) ? 'background-brown-left' : i === 2 ? 'background-brown-right' : 'background-white'} style={i === 3 ? {padding: '50px 100px'} : {padding: '50px 10%'}}>
                <div style={i=== 0 ? {display:'flex', alignItems:'center', width: '80%'} :{display:'flex', alignItems:'center', width: 600} }>

                  {feature.image && <img style={{width: 80, height: 80, marginRight: 50, objectFit:'contain'}} src={feature.image} alt='Om Yoga booking app for yoga teachers and studios'/>}
                  {i === 0 && <img style={{position:'absolute', left:-10, bottom: 30, marginRight: '10vw', height: 250}} src={desktop} alt='Om Yoga studio booking app preview'/>}
                  {i === 4 && <img style={{position:'absolute', left: -10, top: '-12%', marginRight: '10vw', height: 400, width: 400}} src={community} alt='Om Yoga studio booking app preview'/>}

                  <div className='studio-feature-txt' style={(i=== 0 || i ===4) ? {fontSize: 18, color: '#A28F89', marginLeft: '50%'}: {}}>
                    <h3 style={{fontSize: 28, fontWeight: 100, margin: '10px 0'}}>{feature.feature}</h3>
                    <p style={{fontSize: 18, color: '#A28F89'}}>{feature.description}</p>
                  </div>

                  {i === 1 && <img id='studio-phone' style={{position:'absolute', right: '-12%', top: '30%', marginRight: '10vw', height: 350, zIndex:998}} src={fingertips} alt='Om Yoga studio booking app preview'/>}
                  {i === 2 && <img id='studio-circle' style={{position:'absolute', right: '-18%', top: '40%', marginRight: '10vw', height: 487,width:245, zIndex:999}} src={map} alt='Om Yoga studio booking app preview'/>}


        
                </div>
              </div>

          </div>
          )
        })}
        </div>
      </div>

      <div id='studio-carousel'>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
            {features.map((feature, i) => {
            return (
              <div className='mobile-feature'  key={i}>
                    <img style={{width: 80, height: 80, marginRight: 50, objectFit: 'contain'}} src={feature.mobileImg} alt='Om Yoga booking app for yoga teachers and studios'/>
          
                    <div>
                      <h3 style={{fontSize: 28, fontWeight: 100, margin: '10px 0'}}>{feature.feature}</h3>
                      <p style={{fontSize: 18, color: '#313131'}}>{feature.description}</p>
                    </div>
              </div>
            )
          })}
        </Carousel>
      </div>

      <img id='studio-mobile-circle' src={fingertips} alt='Om Yoga studio booking app preview'/>

      {/*Pricing section */}

      <div id='pricing'>
        <h2 style={{fontSize: 32}}>Plans to suit your business</h2>
        <p style={{fontSize: 22, maxWidth: '80vw', width: 1400, marginBottom: 50}}>
          { window.innerWidth > 750 ?
          "At Om Yoga, we understand that every business is unique, and that's why we offer a variety of plans to suit your distinct requirements. From the basic packages for startups to our fully comprehensive signature plan, we have designed our options to accommodate your organisation's size and goals."
          :
          "At Om Yoga, we understand that every business is unique, and that's why we offer a variety of plans to suit your distinct requirements."
          }
        </p>
        
        <div id='desktop-plans' >

          <div className='pricing-container'>
            <div className='pricing-header'>
              <h4>Basic Plan</h4>
            </div>

            <div style={{width: '85%'}}>
                <h2 style={{ marginTop: 0}} className='plan-price'>Free</h2>
                <p style={{margin: '5px 0'}}>No Contract</p>
            </div>

            <div className='plan-info'>
              

              <p style={{fontSize: 14, marginBottom: 0}}>Features include:</p>

              <ul style={{padding: 0}}>
                <li className='pricing-feature'> 
                  <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                  <p>Upload your studio logo</p>
                </li>
                <li className='pricing-feature'> 
                  <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                  <p>Join the yoga studio directory</p>
                </li>
                <li className='pricing-feature'> 
                  <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                  <p>Link to your studio website or booking platform</p>
                </li>
              </ul>
            </div>

              <a href='https://studio.om-yoga.co.uk/login' target='_blank'>
                <button className='cta-btn' style={{backgroundColor: '#A28F89', margin: '30px 0'}}>Sign Up Now</button>
              </a>
          </div>

          <div id='premium-container'>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems:'center'}}>
              <div className='pricing-header' style={{backgroundColor: '#d1c7c4'}}>
                  <h4 style={{color: '#313131'}}>Premium Plan</h4>
                </div>

                <div id='most-popular'>
                  <p style={{margin: 10, color:'white', fontSize: 16}}>Most Popular</p>
                </div>
            </div>

              <div style={{width: '85%'}}>
                  <h2 style={{color: '#313131', marginTop: 30}} className='plan-price'>£49.50<span style={{fontSize: 18}}>/ Month</span></h2>
                  <p style={{margin: '5px 0'}}>First 6 Months</p>

                  <h3 className='full-plan-price'><span className='strikethrough-price'>£99.00</span><span style={{fontSize: 16}}>/ Month</span></h3>
                  <p style={{margin: '5px 0'}}>Cancel at any time</p>
              </div>

              <div className='plan-info'>
               

                <p style={{fontSize: 14, marginBottom: 0}}>Features include:</p>

                <ul style={{padding: 0}}>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Upload your studio logo</p>
                  </li>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Join the yoga studio directory</p>
                  </li>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Link to your studio website</p>
                  </li>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Create and organise timetables</p>
                  </li>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Build a teacher database</p>
                  </li>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Manage payments and subscriptions</p>
                  </li>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Messanger service</p>
                  </li>
                </ul>
              </div>

                <button className='cta-btn' style={{ margin: '30px 0'}} onClick={() => setCalendlyOpen(true)}>Book Free Demo</button>
          </div>

          <div className='pricing-container'>
            <div className='pricing-header'>
              <h4>Signature Plan</h4>
            </div>

            <div style={{width: '85%'}}>
                  <h2 style={{ marginTop: 0}} className='plan-price'>£450<span style={{fontSize: 18}}>/ Month</span></h2>
                  <p style={{margin: '5px 0'}}>Min. 12 Month Contract</p>
              </div>

              <div className='plan-info'>
               

                <p style={{fontSize: 14, marginBottom: 0}}>Features include:</p>

                <ul style={{padding: 0}}>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Enjoy all the benefits of premium</p>
                  </li>
                </ul>
              

              <p style={{fontSize: 14, marginBottom: 0}}>Plus:</p>

              <ul style={{padding: 0}}>
                <li className='pricing-feature'> 
                  <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                  <p>Fully customised app to your own brand, including logo, fonts and colour scheme.</p>
                </li>
              </ul>

              </div>

              <button className='cta-btn' style={{backgroundColor: '#A28F89', margin: '30px 0'}} onClick={() => handleClick("Callback")}>Request Callback</button>
          </div>
        </div>

        <div id='mobile-plans'>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className='mobile-pricing-container'>
            <div className='pricing-header'>
              <h4>Basic Plan</h4>
            </div>

            <div style={{width: '85%'}}>
                <h2 style={{ marginTop: 0}} className='plan-price'>Free</h2>
                <p style={{margin: '5px 0'}}>No Contract</p>
            </div>

            <div className='plan-info'>
              

              <p style={{fontSize: 14, marginBottom: 0}}>Features include:</p>

              <ul style={{padding: 0}}>
                <li className='pricing-feature'> 
                  <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                  <p>Upload your studio logo</p>
                </li>
                <li className='pricing-feature'> 
                  <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                  <p>Join the yoga studio directory</p>
                </li>
                <li className='pricing-feature'> 
                  <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                  <p>Link to your studio website or booking platform</p>
                </li>
              </ul>
            </div>

              <a href='https://studio.om-yoga.co.uk/login' target='_blank'>
                <button className='cta-btn' style={{backgroundColor: '#A28F89', margin: '30px 0'}}>Sign Up Now</button>
              </a>
          </div>

          <div className='mobile-pricing-container' style={{backgroundColor:'#f1f1f1'}}>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems:'center'}}>
              <div className='pricing-header' style={{backgroundColor: '#d1c7c4'}}>
                  <h4 style={{color: '#313131'}}>Premium Plan</h4>
                </div>

                <div id='most-popular'>
                  <p style={{margin: 10, color:'white', fontSize: 16}}>Most Popular</p>
                </div>
            </div>

              <div style={{width: '85%'}}>
                  <h2 style={{color: '#313131', marginTop: 30}} className='plan-price'>£49.50<span style={{fontSize: 18}}>/ Month</span></h2>
                  <p style={{margin: '5px 0'}}>First 6 Months</p>

                  <h3 className='full-plan-price'><span className='strikethrough-price'>£99.00</span><span style={{fontSize: 16}}>/ Month</span></h3>
                  <p style={{margin: '5px 0'}}>Cancel at any time</p>
              </div>

              <div className='plan-info'>
               

                <p style={{fontSize: 14, marginBottom: 0}}>Features include:</p>

                <ul style={{padding: 0}}>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>All of the benifts of a free account</p>
                  </li>
                  <p style={{fontSize: 14, marginBottom: 0}}>Plus:</p>

                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Create and organise timetables</p>
                  </li>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Build a teacher database</p>
                  </li>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Manage payments and subscriptions</p>
                  </li>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Messanger service</p>
                  </li>
                </ul>
              </div>

                <button className='cta-btn' style={{ margin: '30px 0'}} onClick={() => setCalendlyOpen(true)}>Book Free Demo</button>
          </div>

          <div className='mobile-pricing-container'>
            <div className='pricing-header'>
              <h4>Signature Plan</h4>
            </div>

            <div style={{width: '85%'}}>
                  <h2 style={{ marginTop: 0}} className='plan-price'>£450<span style={{fontSize: 18}}>/ Month</span></h2>
                  <p style={{margin: '5px 0'}}>Min. 12 Month Contract</p>
              </div>

              <div className='plan-info'>
               

                <p style={{fontSize: 14, marginBottom: 0}}>Features include:</p>

                <ul style={{padding: 0}}>
                  <li className='pricing-feature'> 
                    <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                    <p>Enjoy all the benefits of premium</p>
                  </li>
                </ul>
              

              <p style={{fontSize: 14, marginBottom: 0}}>Plus:</p>

              <ul style={{padding: 0}}>
                <li className='pricing-feature'> 
                  <img className='check-mark' src={check} alt='Om Yoga booking app feature'/>
                  <p>Fully customised app to your own brand, including logo, fonts and colour scheme.</p>
                </li>
              </ul>

              </div>

              <button className='cta-btn' style={{backgroundColor: '#A28F89', margin: '30px 0'}} onClick={() => handleClick("Callback")}>Request Callback</button>
          </div>
        </Carousel>
        </div>

        <p style={{fontSize: 22, maxWidth: '80vw', width: 1400, marginBottom: 50, marginTop: 100}}>
          {window.innerWidth < 750 ?
          "If you're unsure about which is the ideal plan for your business, get in touch."
          :
          "If you're unsure about which is the ideal plan for your business, get in touch. We understand that every business is unique so we'll guide you towards the perfect fit for your business journey."
          }
          
        </p>
        
        <button id='get-in-touch' onClick={() => handleClick("General")}>Get In Touch</button>
      </div>

      <FAQ />
     {/* <Support />*/}


      <Footer />
    </div>
  )
}
