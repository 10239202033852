import React from 'react'
import './Navbar.css'
import logo from '../../Assets/om.png'
import { Link } from 'react-router-dom'

export default function Navbar() {
  return (
    <header id='logged-in-header'>
        <div style={{display:'flex', alignItems:'center'}}>
          <img id='nav-logo' src={logo} alt="Om Yoga Logo"/>
          <p style={{ fontFamily: 'Arual', fontSize: 30, margin: '0 0 0 20px'}}>om yoga</p>
        </div>
        

        <nav id='desktop-nav'>
            <Link to={'/teachers'} className='nav-link'>Calendar</Link>
            <Link to={'classes'} className='nav-link'>My Classes</Link>
            <Link to={'profile'} className='nav-link'>Profile</Link>

        </nav>

     
      <div className="hamburger-menu">
        <input id="menu__toggle" type="checkbox" />
        <label className="menu__btn" for="menu__toggle">
          <span></span>
        </label>

        <ul className="menu__box">
          <li><Link to={'/teachers'} className="menu__item" >Calendar</Link></li>
          <li><Link to={'classes'} className="menu__item" >My Classes</Link></li>
          <li><Link to={'profile'} className="menu__item" >Profile</Link></li>
       
        </ul>
      </div>
        
    </header>
  )
}
