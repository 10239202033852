import React, { useEffect, useState} from 'react'
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'
import './Profile.css'
import {  signOut } from "firebase/auth";
import { toast } from 'react-toastify'
// firebase
import { auth, storage } from '../../Firebase'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateProfile } from "firebase/auth";

export default function Profile() {

    const { currentUser } = useAuth()

    const [img, setImg] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [showPaymentIntegration, setShowPaymentIntegration] = useState(false)
    const [signupInProgress, setSignupInProgress] = useState(false)

    useEffect(() => {
        getUserInfo()
    },[])

    const getUserInfo = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/teachers/get-teacher-by-id`, {_id: currentUser.uid})
        .then((res) =>{
            console.log(res.data)
            setImg(res.data.img)
            setFirstName(res.data.firstName)
            setLastName(res.data.lastName)
            // notes:
            //   - can only connect to stripe if signup permitted but not yet completed
            //   - once complete, payments will be permitted
            //   - signup permission is an admin initiated action, potentially the fields are not present before that
            setShowPaymentIntegration(res.data.connectData?.signupPermitted && !res.data.connectData?.paymentsPermitted)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const createConnectAccount = () => {
        setSignupInProgress(true)
        axios.post(`${process.env.REACT_APP_API_URL}/payments/create-connect-account`, {
          email: currentUser.email,
        })
        .then((res) => {
            console.log(res.data)
            window.location.href = res.data.url;
        })
        .catch((error) => {
            toast.error("A problem occurred with the payment system")
            console.log(error)
        })
    }

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    }


    const imgRef = ref(storage, `teachers/profile/${currentUser.uid}`);
    const updateImg = (file) => {
        if (file?.size > 2097152) {
            toast.error("Image must be less than 2mb")
        } else if( !file){
            return
        } else {
            uploadBytes(imgRef, file).then((snapshot) => {
                getDownloadURL(imgRef)
                .then((url) => {
                    updateProfile(auth.currentUser, {
                        photoURL: url
                    }).then(() => {
                        setImg(url)
                        saveProfilePic(url)
                    }).catch((error) => {
                        console.log(error)
                        toast.error("Error")
                    });
                })
                .catch((error) => {
                    console.log(error)
                });
            });
        }
    }

    const saveProfilePic = (url) =>{
        axios.post(`${process.env.REACT_APP_API_URL}/teachers/update-profile-pic`, {_id: currentUser.uid, img: url})
        .then((res) => {
            console.log("Updated")
        })
        .catch((e) => {
            console.log(e)
        })
    }

    return (
        <div className='outlet'>
            <div id='profile-container'>
                <div id='profile-avatar' style={img ? {backgroundImage: `url(${img})`} : {backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/zest-b90d0.appspot.com/o/avatar.png?alt=media&token=729becdf-6299-4cbd-9271-ba1442b8eaa9')`}}>
                    <input type="file" accept="image/*" id='img-input' onChange={(e)=>updateImg(e.target.files[0])}/>
                </div>

                <input className='om-input' placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                <input className='om-input' placeholder='Last Name'  value={lastName} onChange={(e) => setLastName(e.target.value)}/>

                {showPaymentIntegration && (
                    <button
                        className='om-btn spaced-profile-btn'
                        onClick={createConnectAccount}
                        disabled={signupInProgress}
                    >
                        Payment Integration
                    </button>
                )}
                <button className='om-btn spaced-profile-btn' onClick={handleLogout}>
                    Logout
                </button>
            </div>
        </div>
    )
}
