import React, {useState} from 'react'
import './CreateAccount.css'
import logo from '../../Assets/om.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { auth } from '../../Firebase'

export default function CreateAccount() {

  let navigate = useNavigate();

  //Form state
  const [accountType, setAccountType] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [saving, setSaving] = useState(false)

  const checkAccountType = () =>{
    if(!accountType){
      toast.error("Please select account type")
    } else if(accountType === 'Student'){
      createStudentAccount()
    } else if(accountType === 'Teacher'){
      createTeacherAccount()
    } else {
      return
    }
  }

  const createTeacherAccount = () =>{
    if(password.length < 6){
      toast.error("Password must be more than 6 characters")
    } else if (!firstName || !lastName || !email){
      toast.error("Missing field")
    } else if(password !== confirmPassword){
      toast.error("passwords don't match")
    } else {

        setSaving(true)
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;

            axios.post(`${process.env.REACT_APP_API_URL}/teachers/create-teacher-account`,
            {_id: user.uid,
              accountType,
              firstName,
              lastName,
              email,
              accountVerified: false,
              admin: false  })
            .then(()=>{
              setSaving(false)
              toast.success("Account created successfully!")
              sendEmailVerification(user)
              .then(() => {
                //Sign out user to allow role to propogate on token
                signOut(auth)
                .then(() =>
                  navigate('/login'))
              })

            })
            .catch((e)=>{
              console.log(e)
              toast.error("Oops, something went wrong")
              setSaving(false)

            })
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(errorMessage)
          setSaving(false)

          // ..
        });
      }

  }

  const createStudentAccount = () =>{
    if(password.length < 6){
      toast.error("Password must be more than 6 characters")
    } else if (!firstName || !lastName || !email){
        alert("Missing field")
    } else if(password !== confirmPassword){
        alert("passwords don't match")
    } else {

        setSaving(true)
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;

            axios.post(`${process.env.REACT_APP_API_URL}/students/create-student-account`,
            {_id: user.uid,
              accountType,
              firstName,
              lastName,
              email,
              admin: false  })
            .then(()=>{
              setSaving(false)
              toast.success("Account created successfully!")
              sendEmailVerification(user)
              .then(() => {
                //Sign out user to allow role to propogate on token
                signOut(auth)
                .then(() =>
                  navigate('/login'))
              })
            })
            .catch((e)=>{
              console.log(e)
              toast.error("Oops, something went wrong")
              setSaving(false)

            })
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(errorMessage)
          setSaving(false)

          // ..
        });
      }

  }

  return (
    <div id='new-account-page'>
        <div id='white-overlay'/>

        <div id='new-account-container'>
            <img id='login-logo' src={logo} alt='Om yoga logo'/>
            <h1 style={{fontFamily: 'Arual', fontWeight: 300, color: 'white'}}>om Yoga</h1>

            <div id='select-container'>
              <label style={{ color: 'white'}}>I am a</label>
              <select id='account-select' value={accountType} onChange={(e) => setAccountType(e.target.value)}>
                <option value="" disabled>Select</option>
                <option value='Student'>Student</option>
                <option value='Teacher'>Teacher</option>
              </select>
            </div>

            <input className='account-input' type='text' placeholder='First name' value={firstName} onChange={(e)=> setFirstName(e.target.value) }/>
            <input className='account-input' type='text' placeholder='Last name' value={lastName} onChange={(e)=> setLastName(e.target.value) }/>
            <input className='account-input' type='email' placeholder='Email address' value={email} onChange={(e)=> setEmail(e.target.value) }/>
            <input className='account-input' type='password' placeholder='Password' value={password} onChange={(e)=> setPassword(e.target.value) }/>
            <input className='account-input' type='password' placeholder='Confirm password' value={confirmPassword} onChange={(e)=> setConfirmPassword(e.target.value) }/>


            <button id='new-account-btn' onClick={checkAccountType} disabled={saving}>{saving? 'Creating...' : "Create account"}</button>

            <p style={{color:'white'}}>Already have an account? <Link to={'/login'} style={{color:'white'}}>Login</Link></p>

        </div>
    </div>
  )
}
