import React, {useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import Modal from 'react-modal';
import './Calendar.css'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import { toast } from 'react-toastify'
import moment from 'moment'

Modal.setAppElement('#root');

export default function StudentCalendar() {

  const { currentUser } = useAuth()
  const [cancelling, setCancelling] = useState(false)
  const [bookings, setBookings] = useState([])
  const [bookingOpen, setBookingOpen] = useState(false);
  //View event state
  const [booking, setBooking] = useState("")

  useEffect(() => {
    getBookings()
  }, [])

  const getBookings = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/bookings/get-bookings-by-student`, {studentId: currentUser.uid})
    .then((res) => {
      setBookings(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }


  function openEventModal() {
    setBookingOpen(true);
  }

  function closeEventModal() {
    setBookingOpen(false);
  }

  const handleEventClick = (event) => {
    setBooking(event.event)
    openEventModal()
  }

  const handleCancellation = () => {
    let payload = {
      ...booking._def.extendedProps,
      start: booking.start
    }
      setCancelling(true)
      axios.post(`${process.env.REACT_APP_API_URL}/bookings/cancel-booking`, payload)
      .then((res) => {
          toast.success("Cancelled");
          getBookings()
          setCancelling(false)
          closeEventModal()
      })
      .catch((error) => {
          console.log(error)
          setCancelling(false)
      })
}

  return (
    <div className='outlet'>
        <div style={{width: 1200, maxWidth: '90vw', margin: '1rem auto'}}>
            <FullCalendar
                plugins={[ dayGridPlugin, interactionPlugin ]}
                eventSources={[bookings]}
                eventClick={(event) => handleEventClick(event)}
                eventDisplay='block'
                eventClassNames={(arg) =>{
                    return [ 'normal' ]
                }}
                firstDay={1}
            />
        </div>


      {/*View event*/}
      <Modal
        isOpen={bookingOpen}
        onRequestClose={closeEventModal}
        style={{...customStyles, overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(70, 70, 70, 0.5)',

        }}}
        contentLabel="Example Modal"
      >
          {booking &&
          <>
            <h2>{moment(booking.start).format('DD MMM YYYY')}</h2>
            <h3>{booking.title}</h3>

            <button className='modal-close-btn' onClick={handleCancellation} disabled={cancelling}>{cancelling ? "Cancelling" : "Cancel Class"}</button>

          </>
          }

        <button className='modal-close-btn' onClick={closeEventModal}>close</button>

      </Modal>

    </div>
  )
}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display:'flex',
    flexDirection:'column',
    width: 600,
    maxWidth: '95vw',
    height: 'auto',
    maxHeight: '90vh',
    overflowY:'scroll',
    textAlign:'center'
  },
};