import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import map from '../../Assets/googlemap.png'
import { useAuth } from '../../Context/AuthContext'
import ModalBooking from './Modals/ModalBooking'
import ModalLogin from './Modals/ModalLogin'
import ModalSignUp from './Modals/ModalSignUp'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function ExploreClasses({classes, getClasses, loading}) {
    const [showModal, setShowModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loginOpen, setLoginOpen] = useState(false)
    const [openSignup, setOpenSignup] = useState(false)
    const [bookings, setBookings] = useState([])
    const [cancelling, setCancelling] = useState(false)

    let { currentUser } = useAuth()

     useEffect(() => {
         if(currentUser){
             getBookings()
         }
     }, [currentUser])

     const getBookings = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/bookings/get-future-bookings-by-student`, {studentId: currentUser.uid})
        .then((res) => {
            setBookings(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const handleCancellation = (booking) => {
        setCancelling(true)
        axios.post(`${process.env.REACT_APP_API_URL}/bookings/cancel-booking`, booking)
        .then((res) => {
            toast.success(res.data);
            getBookings()
            setCancelling(false)
        })
        .catch((error) => {
            console.log(error)
            setCancelling(false)
        })
    }

    const handleBookNow = (event) => {
        if (!currentUser) {
            // Open login modal
            setLoginOpen(true)
        } else {
            // Set the selected event and show the modal
            setSelectedEvent(event);
            setShowModal(true);
        }
    };

    const closeModal = () => {
        // Clear the selected event and hide the modal
        setSelectedEvent(null);
        setShowModal(false);
    };

  return (

    <div style={{ minHeight: '70vh'}}>
         { loading ?
        <Skeleton className='event-container' style={{height: 150, marginTop: 20}} count={20} inline={true}/>
        :
        classes.map((event, i) => {
            let bookedEvent = bookings.filter((booking) => booking.eventId === event._id)

            return (
                <div className='event-container hover' key={i}>
                    <div className='class-date'>
                        <div className='class-month'>
                            <p className='month-title'>{moment(event.start).format('MMMM')}</p>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding: 10}}>
                            <p className='month-date'>{moment(event.start).format('DD')}</p>
                            <p style={{color: '#a28f89', fontWeight: 'bold', margin: 0}}>{moment(event.start).format('dddd')}</p>
                        </div>
                    </div>

                    <div style={{width: '60%'}}>
                        <p style={{fontSize: 24}}>{event.className}</p>
                        <p style={{color: '#717171'}}>{event.venueName}</p>
                        <p style={{color: '#717171'}}>{moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}</p>

                    </div>

                    <div className='class-list-btns'>
                        {event.classType === 'Studio' ? (
                            <a href={event.bookingLink} target='_blank' rel='noopener noreferrer'>
                                <button className='om-btn'>
                                    External Booking
                                </button>
                            </a>
                        ) : (
                            <button
                                className='om-btn'
                                style={bookedEvent.length > 0 ? {borderColor: 'green', color: 'green'} : null}
                                onClick={() => handleBookNow(event)}
                                disabled={bookedEvent.length > 0}
                            >
                                {bookedEvent.length > 0 ? "Booked" : "Book Now"}
                            </button>
                        )}

                        {bookedEvent.length > 0 &&
                            <button
                                className='om-btn'
                                style={{borderColor: 'crimson', color: 'crimson', borderWidth: 1, marginTop: 10}}
                                onClick={() => handleCancellation(bookedEvent[0])}
                                disabled={cancelling}
                                    >
                                        {cancelling ? "Cancelling" : "Cancel Booking"}
                                    </button>

                        }

                        {event.googleMaps &&
                            <a href={event.googleMaps} style={{textDecoration:'none'}} target='_blank'>
                                <button className='map-btn'>
                                    <img style={{height: 25, width: 25}} src={map} alt='Om Yoga booking app link to google maps'/>
                                    <p style={{color:'white'}}>View on Map</p>
                                </button>
                            </a>
                        }
                    </div>
                </div>
            )
            })
        }

        {loginOpen &&
            <ModalLogin
                setOpen={setLoginOpen}
                setOpenSignup={setOpenSignup}
            />
        }

        {openSignup &&
            <ModalSignUp
                setOpen={setOpenSignup}
                setOpenLogin={setLoginOpen}
            />
        }

        {showModal && selectedEvent &&
            <ModalBooking
                event={selectedEvent}
                onClose={closeModal}
                getEvents={getClasses}
                getBookings={getBookings}
                bookings={bookings}
            />
        }
    </div>

  )
}

