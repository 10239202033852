
import React, { useState } from 'react'
import './Modal.css'
import close from '../../../Assets/close.png'
import { toast } from 'react-toastify'
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { auth } from '../../../Firebase'
import axios from 'axios'

export default function ModalSignUp({setOpen, setOpenLogin}) {

  //Form state
  const [accountType, setAccountType] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [loading, setLoading] = useState(false)

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents click event from bubbling up to the modal div
  };

  const checkAccountType = () =>{
    if(!accountType){
      toast.error("Please select account type")
    } else if(accountType === 'Student'){
      createStudentAccount()
    } else if(accountType === 'Teacher'){
      createTeacherAccount()
    } else {
      return
    }
  }

  const createTeacherAccount = () =>{
    if(password.length < 6){
      toast.error("Password must be more than 6 characters")
    } else if (!firstName || !lastName || !email){
      toast.error("Missing field")
    } else if(password !== confirmPassword){
      toast.error("passwords don't match")
    } else {

      setLoading(true)
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;

            axios.post(`${process.env.REACT_APP_API_URL}/teachers/create-teacher-account`,
            {_id: user.uid,
              accountType,
              firstName,
              lastName,
              email,
              accountVerified: false,
              admin: false  })
            .then(()=>{
              toast.success("Account created successfully!")
              sendEmailVerification(user)
              .then(() => {
                // Allow role to propogate on token
                window.location.reload()

              })

            })
            .catch((e)=>{
              console.log(e)
              toast.error("Oops, something went wrong")
              setLoading(false)

            })
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(errorMessage)
          setLoading(false)

          // ..
        });
      }

  }

  const createStudentAccount = () =>{
    if(password.length < 6){
      toast.error("Password must be more than 6 characters")
    } else if (!firstName || !lastName || !email){
        alert("Missing field")
    } else if(password !== confirmPassword){
        alert("passwords don't match")
    } else {

      setLoading(true)
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;

            axios.post(`${process.env.REACT_APP_API_URL}/students/create-student-account`,
            {_id: user.uid,
              accountType,
              firstName,
              lastName,
              email,
              admin: false  })
            .then(()=>{
              toast.success("Account created successfully!")
              sendEmailVerification(user)
              .then(() => {
                // Allow role to propogate on token
                window.location.reload()

              })

            })
            .catch((e)=>{
              console.log(e)
              toast.error("Oops, something went wrong")
              setLoading(false)

            })
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(errorMessage)
          setLoading(false)

          // ..
        });
      }

  }

  const handleSwitch = () => {
    setOpen(false)
    setOpenLogin(true)
  }

  return (
    <div className='modal' onClick={(e) =>  setOpen(false)}>

        <div className='modal-container' onClick={handleContainerClick}>
          <img className='hover close-modal-btn' src={close} alt='Close Om Yoga login box' onClick={() => setOpen(false)}/>

          <h3 className='modal-header'>Sign Up</h3>


            <select className='single-input'value={accountType} onChange={(e) => setAccountType(e.target.value)}>
              <option value="" disabled>Account type</option>
              <option value='Student'>Student</option>
              <option value='Teacher'>Teacher</option>
            </select>

            <div className='vertical-spacer' />


            <input className='top-input' type='text' placeholder='First name' value={firstName} onChange={(e)=> setFirstName(e.target.value) }/>
            <input className='middle-input' type='text' placeholder='Last name' value={lastName} onChange={(e)=> setLastName(e.target.value) }/>
            <input className='bottom-input' type='email' placeholder='Email address' value={email} onChange={(e)=> setEmail(e.target.value) }/>

            <div className='vertical-spacer' />

            <input className='top-input' type='password' placeholder='Password' value={password} onChange={(e)=> setPassword(e.target.value) }/>
            <input className='bottom-input' type='password' placeholder='Confirm password' value={confirmPassword} onChange={(e)=> setConfirmPassword(e.target.value) }/>


          <button className='modal-btn' onClick={checkAccountType} disabled={loading}>
            {loading ? 'Creating...' : "Create free account"}
          </button>

          <p>Already have an account? <span className='hover' style={{textDecoration: 'underline'}} onClick={handleSwitch}>Login</span></p>

        </div>

    </div>
  )
}
