import React, { useState } from 'react'
import './Modal.css'
import { useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import close from '../../../Assets/close.png'
import copy from '../../../Assets/copy.png'

export default function ModalShare({setOpen}) {

    const location = useLocation()
    const [copied, setCopied] = useState(false);
  
    const handleCopy = () => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000); // Reset copied state after 2 seconds
    };

    const handleContainerClick = (e) => {
      e.stopPropagation(); // Prevents click event from bubbling up to the modal div
    };

  return (
    <div className='modal' onClick={() =>  setOpen(false)}>

        <div className='modal-container' onClick={handleContainerClick}>
            <img className='hover close-modal-btn'  src={close} alt='Close Om Yoga share box' onClick={() => setOpen(false)}/>

            <h3 className='modal-header'>Share this profile</h3>
            <CopyToClipboard text={window.location.href} onCopy={handleCopy}>
                <div className='share-container hover'>
                    <img  style={{height: 20, width: 20, marginRight: 10}} src={copy} alt='Share om yoga profile'/>
                    <p>Copy Link</p>
                </div>
            </CopyToClipboard>
            
            {copied ? <span style={{ color: 'green' }}>Copied!</span> : null}

        </div>
        
    </div>
  )
}
