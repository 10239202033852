import React, { useState } from 'react'
import { TypeAnimation } from 'react-type-animation';
import './Home.css'
import Footer from './Footer';
import { Link, useNavigate } from 'react-router-dom';
import closeWhite from '../../Assets/close-white.png'
import Header from './Header';
//App icons
import apple from '../../Assets/apple-black.webp'
import google from '../../Assets/google-black.webp'

export default function Home() {

    const [choice, setChoice] = useState("")
    let navigate = useNavigate()

    const handleSelection = (selection) => {
        if(window.innerWidth < 1001){
            navigate(`${selection.toLowerCase()}`)
        } else {
            if(selection === choice) {
                setChoice("")
            } else {
                setChoice(selection)
            }
        }
       
    }

  return (
    <>

    <Header />

    
    <div id='home'>

        <div id='home-left-container' >
            <p  style={{ fontSize: 48, fontFamily: 'Satoshi', color:'#313131', maxWidth: 350, lineHeight: 1.5, margin: '0 auto' }}>
                Find calm in managing your yoga 
                <span>
                    <TypeAnimation
                        preRenderFirstString={true}
                        sequence={[
                            // Same substring at the start will only be typed once, initially
                            ` studio.`,
                            2000,
                            ' teaching.',
                            2000,
                            ' practice.',
                            2000
                            ]}
                        speed={40}
                        style={{ color:'#A28F89', fontWeight:'bold' }}
                        repeat={Infinity}
                    />
                </span>
            </p>

            <div id='store-links'>
                <a href='https://apps.apple.com/uk/app/om-yoga/id6470202583' target='_blank'>
                <img style={{height: 52, margin: 5}} src={apple} alt='app store link to Om Yoga booking app for studios '/>
                </a>
                <a  href='https://play.google.com/store/apps/details?id=com.omyoga.omyoga&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank'>
                <img style={{height: 52, margin: 5}} src={google} alt='app store link to Om Yoga booking app for studios '/>
                </a>

            </div>

            <div className={choice === "Teacher" ? 'home-choice-info' :'home-choice-info-hidden' }>
                <p>Transform the way you manage and grow your yoga business.</p>
                <Link to={'/teacher'}>
                    <button className='learn-more-btn'>Learn more</button>
                </Link>
            </div>

            <div className={choice === "Student" ? 'home-choice-info' :'home-choice-info-hidden' }>
                <p>Make your yoga practice flexible around you.</p>
                <Link to={'/student'}>
                    <button className='learn-more-btn'>Learn more</button>
                </Link>
            </div>

            <div className={choice === "Studio" ? 'home-choice-info' :'home-choice-info-hidden' }>
                <p>Elevate your yoga studio business with simplicity.</p>
                <Link to={'/studio'}>
                    <button className='learn-more-btn'>Learn more</button>
                </Link>
            </div>

        </div>
        
        <div id='home-image-container'>
            <div id='home-teacher' className={choice === "Teacher" ? 'img-open' : choice === "" ? 'img-standard' : 'img-closed'} onClick={() => handleSelection('Teacher')}>
                {choice === "Teacher" && <img style={{width: 50, height: 50}} src={closeWhite} alt='Om Yoga App close image'/>}
                <p className='image-for'>for teachers</p>
            </div>

            <div id='home-student'  className={choice === "Student" ? 'img-open' : choice === "" ? 'img-standard' : 'img-closed'} onClick={() => handleSelection('Student')}>
                {choice === "Student" && <img style={{width: 50, height: 50}} src={closeWhite} alt='Om Yoga App close image'/>}
                <p className='image-for'>for individuals</p>
            </div>

            <div id='home-studio'  className={choice === "Studio" ? 'img-open' : choice === "" ? 'img-standard' : 'img-closed'} onClick={() => handleSelection('Studio')}>
                {choice === "Studio" && <img style={{width: 50, height: 50}} src={closeWhite} alt='Om Yoga App close image'/>}
                <p className='image-for'>for studios</p>
            </div>

        </div>
    </div>

    <Footer />
    </>
  )
}
