import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TeacherUI from './Components/TeacherUI.js/TeacherUI';
import Calendar from './Components/TeacherUI.js/Calendar';
import Classes from './Components/TeacherUI.js/Classes';
import Login from './Components/PublicUI/Login';
import CreateAccount from './Components/PublicUI/CreateAccount';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RequireAuth from './Components/RequireAuth';
import { AuthProvider } from './Context/AuthContext';
import Profile from './Components/TeacherUI.js/Profile';
import StudentCalendar from './Components/StudentUI/StudentCalendar';
import StudentUI from './Components/StudentUI/StudentUI';
import BlogList from './Components/Landing/BlogList';
import Terms from './Components/PublicUI/Terms';
import Home from './Components/Landing/Home';
import TeacherPage from './Components/Landing/TeacherPage';
import StudentPage from './Components/Landing/StudentPage';
import StudioPage from './Components/Landing/StudioPage';
import 'react-loading-skeleton/dist/skeleton.css'
import SupportChat from './Components/SupportChat';
import Messages from './Components/PublicUI/Messages';
import Chat from './Components/PublicUI/Chat';

// Carousel css
import 'react-multi-carousel/lib/styles.css';
import ScrollToTop from './Components/ScrollToTop';
import Explore from './Components/PublicUI/Explore';
import TeacherProfile from './Components/PublicUI/TeacherProfile';
import StudioProfile from './Components/PublicUI/StudioProfile';


function App() {
  return (
    <AuthProvider>
      <BrowserRouter >
        <ScrollToTop />
        <SupportChat>
        <Routes>

          {/*Public routes */}
          <Route exactpath='/' element={<Home />} />
          <Route path='/teacher' element={<TeacherPage />} />
          <Route path='/student' element={<StudentPage />} />
          <Route path='/studio' element={<StudioPage />} />
          <Route path='/login' element={<Login />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/blogs' element={<BlogList />} />
          <Route path='/create-account' element={<CreateAccount />} />
          <Route path='/explore' element={<Explore />} />
          <Route path='/explore/teachers/:username' element={<TeacherProfile />} />
          <Route path='/explore/studios/:id' element={<StudioProfile />} />
          <Route path='/messages/:id' element={<Messages />} >
            <Route element={<Chat />} />
            <Route path=':chatId' element={<Chat />} />
          </Route>

          {/*Teacher routes */}
          <Route element={<RequireAuth requiredRole="Teacher"  />} >
            <Route path='/teachers' element={<TeacherUI />} >
              <Route index element={<Calendar />}/>
              <Route path='profile' element={<Profile />}/>
              <Route path='classes' element={<Classes />}/>
            </Route>
          </Route>

          {/*Student  routes */}
          <Route element={<RequireAuth requiredRole="Student"  />} >
            <Route path='/students' element={<StudentUI />} >
              <Route index element={<StudentCalendar />}/>
            </Route>
          </Route>

          {/* Wildcard route for redirecting to login page */}
          <Route path='*' element={<Home/>} />
        </Routes>
        </SupportChat>
      </BrowserRouter>
      <ToastContainer position="top-center" />
  </AuthProvider>
  );
}

export default App;