import React, { useState} from 'react'
import './Login.css'
import logo from '../../Assets/om.png'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";
import { toast } from 'react-toastify'
import { auth } from '../../Firebase'

export default function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  let navigate = useNavigate()

  const handleLogin = () => {
    if (!email || !password) {
      toast.error("Please input all fields")
    } else {
      signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        // Signed in
        auth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          if (idTokenResult.claims.role === 'Teacher') {
            navigate('/teachers')
          } else {
            // otherwise this means they are an artist there are no other roles as yet
            navigate('/students')
          }
        })
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
      });
    }
  }

  const resetPassword = () => {
    if (!email) {
      toast.error("Please input email")
    } else {
      sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Password reset email sent!")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
      });
    }
  }


  return (
    <div id='login-page'>
      <div id='login-overlay'/>
        <div id='login-container'>
            <img id='login-logo' src={logo} alt='Om yoga logo'/>
            <h1 style={{fontFamily: 'Arual', fontWeight: 300, color:'white'}}>om Yoga</h1>

            <input className='login-input' type='email' placeholder='Email address' value={email} onChange={(e) => setEmail(e.target.value)}/>
            <input className='login-input' type='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)}/>

            {/* disable for now onClick={handleLogin} until client functionality aligned with mobile */}
            <button id='login-btn' >Login</button>

            <p style={{color:'white'}}>Don<span style={{fontFamily:'sans-serif', color:'white'}}>'</span>t have an account?  <Link to={'/create-account'} style={{color:'white'}}>Create account</Link></p>

        </div>
    </div>
  )
}
