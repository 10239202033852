import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import follow from '../../Assets/add-friend.png'
import followingIcon from '../../Assets/following.png'
import { useAuth } from '../../Context/AuthContext'
import { useNavigate } from 'react-router-dom'
import ModalLogin from './Modals/ModalLogin'
import axios from 'axios'
import ModalSignUp from './Modals/ModalSignUp'

export default function ExploreTeachers({teachers, loading}) {

    let { currentUser, role } = useAuth()
    let navigate = useNavigate()

    const [openLogin, setOpenLogin] = useState(false)
    const [openSignup, setOpenSignup] = useState(false)
    const [following, setFollowing] = useState([])

    useEffect(() => {
        if(currentUser){
            getUser()
        }
    }, [currentUser])

    const getUser = () => {
        if(role === "Teacher") {
            axios.post(`${process.env.REACT_APP_API_URL}/teachers/get-teacher-by-id`, {_id: currentUser.uid})
            .then((res) =>{
                setFollowing(res.data.following)
            })
            .catch((e) => {
                console.log(e)
            })
        } else if(role === "Student"){
            axios.post(`${process.env.REACT_APP_API_URL}/students/get-student-by-id`, {_id: currentUser.uid})
            .then((res) =>{
                setFollowing(res.data.following)
            })
            .catch((e) => {
                console.log(e)
            })
        } else if(role === "Studio"){
            axios.post(`${process.env.REACT_APP_API_URL}/studio/get-studio-by-id`, {id: currentUser.uid})
            .then((res) =>{
                setFollowing(res.data.following)
            })
            .catch((e) => {
                console.log(e)
            })
        }

    }

    const followTeacher = (e, teacher) => {

        e.stopPropagation()

        let payload = {
            teacherName: `${teacher.firstName} ${teacher.lastName}`,
            teacherId : teacher._id,
            teacherImg: teacher.img
        }

        if(!currentUser){
            setOpenLogin(true)
        } else if(role === 'Teacher'){
            axios.post(`${process.env.REACT_APP_API_URL}/teachers/follow-teacher`, {followerId: currentUser.uid, payload})
            .then(() => {
                getUser()
            })
            .catch((e) => {
                console.log(e)
            })
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/students/follow-teacher`, {followerId: currentUser.uid, payload})
            .then(() => {
                getUser()
            })
            .catch((e) => {
                console.log(e)
            })
        }
    }

    const unFollowTeacher = (e, teacher) => {
        e.stopPropagation()

        if(role === 'Teacher'){
            axios.post(`${process.env.REACT_APP_API_URL}/teachers/unfollow-teacher`, {followerId: currentUser.uid, teacherId: teacher._id})
            .then(() => {
                getUser()
            })
            .catch((e) => {
                console.log(e)
            })
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/students/unfollow-teacher`, {followerId: currentUser.uid, teacherId: teacher._id})
            .then(() => {
                getUser()
            })
            .catch((e) => {
                console.log(e)
            })
        }
    }

  return (
    <div className='teachers-container'>
        {openLogin &&
            <ModalLogin
                setOpen={setOpenLogin}
                setOpenSignup={setOpenSignup}
            />}

        {openSignup &&
            <ModalSignUp
                setOpen={setOpenSignup}
                setOpenLogin={setOpenLogin}
            />
        }

        { loading ?
        <Skeleton style={{width: '20vw', height: '20vw', borderRadius: 12, margin: '1rem'}} count={20} inline={true}/>

        :
        teachers.map((teacher, i) => {
            let follows = following.some((elem) => elem.teacherId == teacher._id)
            return (
                <div className='teacher-container hover' key={i} onClick={() => navigate(`/explore/teachers/${teacher.username}`) }>
                    <img className='follow-icon' src={follows ? followingIcon : follow} alt='Om Yoga follow teacher icon'
                        onClick={ follows
                            ?
                            (e) => unFollowTeacher(e, teacher)
                            :
                            (e) => followTeacher(e, teacher)} />
                    <img className='teacher-img' src={teacher.img}/>
                    <div>
                        <p style={{fontWeight: 700, fontSize: 18}}>{teacher.firstName} {teacher.lastName}</p>
                        <p className='teacher-location'>UK</p>
                    </div>
                </div>
            )
        })}
    </div>
  )
}
