import React, { useState, useEffect } from 'react'
import './Messages.css'
import search from '../../Assets/search.png'
import moment from 'moment'
import { Link, Outlet, useNavigate, useParams, useOutletContext } from 'react-router-dom'
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'
import Header from '../Landing/Header'
import logo from '../../Assets/om.png'
import DropDownMenu from './DropDownMenu'

export default function Messages() {

    let { id } = useParams()

    let navigate = useNavigate()
    let { currentUser } = useAuth()

    const [chats, setChats] = useState([])
    const [selectedChat, setSelectedChat] = useState(null)

    useEffect(() => {
        getChats()
    }, [])

    useEffect(() => {
        if (selectedChat && !currentUser) {
            setChats(null)
        }
    }, [currentUser])

    const getChats = () => {

        axios.post(`${process.env.REACT_APP_API_URL}/chats/get-chats-by-id`, {id: currentUser?.uid || id})
        .then((res) => {
            // participant[0] represents initiator/sender
            const sortedChats = res.data.sort((a, b) => new Date(b.lastMsgAdded) - new Date(a.lastMsgAdded));
            setChats(sortedChats);

            // Select the first chat as the default selectedChat if it's not already selected
            if (!selectedChat && sortedChats.length > 0) {
                setSelectedChat(res.data[0])
                navigate(res.data[0]._id)
            }
        })
        .catch((error) => {
            console.log("Error getting chats", error)
        })
    }

    return (
        <div>

            <Header />
            <p style={{ borderBottom: '1px solid #dfdfdf' }}></p>


            <div id='message-contents'>
                <div className='om-row' style={{justifyContent: 'space-between'}}>
                    <p className='om-header'>Inbox</p>
                    <div className='om-row'>
                        <div
                            className='om-message-input'
                            style={{paddingLeft: 'unset', width: 250, marginRight: 10, position: 'relative'}}
                        >
                            <img src={search} alt='Search icon' style={{height: 25, position: 'absolute', left: 10, top: 10}}/>
                            <input style={{border: 'unset', background: 'unset', height: '100%', width: '100%', paddingLeft: 50, fontSize: 16}}  placeholder='Search messages' />
                        </div>
                        <button className='primary-btn'>Search</button>
                    </div>
                </div>

                <div className='om-row'>
                    <div id='messages-left-container'>
                        <div className='tab-container' style={{justifyContent: 'left', margin: 0, borderRadius: 0}}>
                            <p style={{color:'#313131'}}>Chats</p>
                            <div className='tab-square'>
                                <p style={{color:'#313131'}}>{chats.length}</p>
                            </div>
                        </div>

                        {(chats.length < 1 ?
                            <p style={{textAlign:'center', color: 'grey', marginTop: 100}}>Your inbox is empty</p>
                        :
                            chats.map((msg, i) => {
                                const isSelected = selectedChat && selectedChat._id === msg._id;

                                return (
                                    <div
                                        key={i}
                                        className={`msg-snapshot ${isSelected ? 'selected-chat' : ''}`}
                                        onClick={() =>{ setSelectedChat(msg); navigate(msg._id) } }
                                    >
                                        {msg.participants[0]?.img ?
                                            <div
                                                className='msg-avatar'
                                                style={{backgroundImage: `url(${msg.participants[1]?.img})`, backgroundSize:'cover', backgroundPosition:'center'}}
                                            >
                                            </div>
                                        :
                                            <div className='msg-avatar'>
                                                <p>{msg.participants[1]?.name?.split(" ")[0]?.charAt(0)}{msg.participants[1]?.name?.split(" ")[1]?.charAt(0)}</p>
                                            </div>
                                        }
                                        <div
                                            style={{flexDirection: 'column', width: '80%', height: '100%', overflow: 'hidden'}}
                                        >
                                            <div
                                                style={msg.receiverRead ? {display: 'flex', justifyContent: 'space-between', color: 'grey', fontSize: 14} : {display: 'flex', justifyContent: 'space-between', color: '#313131', fontSize: 14} }
                                            >
                                                <p style={{textTransform: 'uppercase'}}>{msg.participants[0]?.name}</p>
                                                {msg.ticketId &&
                                                    <span style={{color: 'grey', fontSize: '0.7rem', marginTop: '1.3em'}}>
                                                        ticket {msg.ticketId.slice(-10)}
                                                </span>}
                                                <p>{moment(msg.lastMsgAdded).fromNow()}</p>
                                            </div>

                                            <p style={ msg.receiverRead ? {marginTop: 0, color:'grey'} :{marginTop: 0, color:'#313131'}}>{msg.lastMsg}</p>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>

                    <div id='messages-right-container'>
                        <Outlet context={{getChats, senderName: selectedChat?.participants[1].name, selectedChat}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
