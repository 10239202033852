import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import './Profiles.css'
import moment from 'moment'
import map from '../../Assets/googlemap.png'
import share from '../../Assets/share.png'
import follow from '../../Assets/add.png'
import followingIcon from '../../Assets/following-profile.png'
import back from '../../Assets/back.png'
import ProfileHeader from './ProfileHeader'
import Footer from '../Landing/Footer'
import ModalShare from './Modals/ModalShare'
import { useAuth } from '../../Context/AuthContext'
import ModalLogin from './Modals/ModalLogin'
import ModalBooking from './Modals/ModalBooking'
import fb from '../../Assets/facebook.png'
import insta from '../../Assets/instagram.png'
import ModalSignUp from './Modals/ModalSignUp'
import { toast } from 'react-toastify'
import ModalPassPurchase from './Modals/ModalPassPurchase'

export default function TeacherProfile() {

    let { username } = useParams()
    let { currentUser, role } = useAuth()
    let navigate = useNavigate()
    const [id, setId] = useState("")
    const [teacherInfo, setTeacherInfo] = useState("")
    const [events, setEvents] = useState([])
    const [shareOpen, setShareOpen] = useState(false)
    const [loginOpen, setLoginOpen] = useState(false)
    const [openSignup, setOpenSignup] = useState(false)
    const [bookingOpen, setBookingOpen] = useState(false);
    const [passOpen, setPassOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [following, setFollowing] = useState(false)
    const [bookings, setBookings] = useState([])
    const [cancelling, setCancelling] = useState(false)
    const [passes, setPasses] = useState([])
    const [selectedPass, setSelectedPass] = useState("")
    const [ownPasses, setOwnPasses] = useState([])
    const [ownPassesLoaded, setOwnPassesLoaded] = useState(false)

    const textareaRef = useRef(null);

    useEffect(() => {
        textareaRef.current.style.height = "0px";
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + "px";
    }, [teacherInfo]);

    useEffect(() => {
        getUser()
        // Get Teacher info
        getProfile()

    }, [])

    useEffect(() => {
        if (id) {
            // If bookings is updated from a new booking it will rerender with a booked status
            getEvents()
            // Get passes for teacher
            getPasses()
        }
    }, [bookings, id])

    useEffect(() => {
        if (currentUser && teacherInfo) {
            getBookings()
            getOwnPasses()
        } else {
            // clear user details when no user (logout)
            setBookings([])
            setOwnPasses([])
            setOwnPassesLoaded(false)
        }
    }, [currentUser, teacherInfo])


    const getPasses = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/passes/get-teacher-passes`, {teacherId: id})
        .then((res) => {
            setPasses(res.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const getProfile = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/teachers/get-teacher-by-username`, {username})
        .then((res) => {
            setTeacherInfo(res.data[0])
            setId(res.data[0]._id)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const getEvents = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/events/get-future-events-by-teacher`, {teacherId: id})
        .then((res) => {
            setEvents(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const getUser = () => {
        if (role === "Teacher") {
            axios.post(`${process.env.REACT_APP_API_URL}/teachers/get-teacher-by-id`, {_id: currentUser.uid})
            .then((res) =>{
                setFollowing(res.data.following.some((elem) => elem.teacherId == id))
            })
            .catch((e) => {
                console.log(e)
            })
        } else if (role === "Student"){
            axios.post(`${process.env.REACT_APP_API_URL}/students/get-student-by-id`, {_id: currentUser.uid})
            .then((res) =>{
                setFollowing(res.data.following.some((elem) => elem.teacherId == id))
            })
            .catch((e) => {
                console.log(e)
            })
        } else if (role === "Studio"){
            axios.post(`${process.env.REACT_APP_API_URL}/studio/get-studio-by-id`, {id: currentUser.uid})
            .then((res) =>{
                setFollowing(res.data.following.some((elem) => elem.teacherId == id))
            })
            .catch((e) => {
                console.log(e)
            })
        }
    }

    const getBookings = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/bookings/get-future-bookings-by-student`, {studentId: currentUser.uid})
        .then((res) => {
            setBookings(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const getOwnPasses = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/passes/get-applicable-passes`, {studentId: currentUser.uid, teacherId: teacherInfo._id})
        .then((res) => {
            setOwnPasses(res.data)
            setOwnPassesLoaded(true)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const followTeacher = () => {

        let payload = {
            teacherName: `${teacherInfo.firstName} ${teacherInfo.lastName}`,
            teacherId : teacherInfo._id,
            teacherImg: teacherInfo.img
        }

        if (!currentUser) {
            setLoginOpen(true)
        } else if (role === 'Teacher') {
            axios.post(`${process.env.REACT_APP_API_URL}/teachers/follow-teacher`, {followerId: currentUser.uid, payload})
            .then(() => {
                getUser()
            })
            .catch((e) => {
                console.log(e)
            })
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/students/follow-teacher`, {followerId: currentUser.uid, payload})
            .then(() => {
                getUser()
            })
            .catch((e) => {
                console.log(e)
            })
        }
    }

    const unFollowTeacher = () => {

        if(role === 'Teacher'){
            axios.post(`${process.env.REACT_APP_API_URL}/teachers/unfollow-teacher`, {followerId: currentUser.uid, teacherId: teacherInfo._id})
            .then(() => {
                getUser()
            })
            .catch((e) => {
                console.log(e)
            })
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/students/unfollow-teacher`, {followerId: currentUser.uid, teacherId: teacherInfo._id})
            .then(() => {
                getUser()
            })
            .catch((e) => {
                console.log(e)
            })
        }
    }

    const handleBookNow = (event) => {
        if (!currentUser){
            // Open login modal
            setLoginOpen(true)
        } else {
            // Set the selected event and show the modal
            setSelectedEvent(event);
            setBookingOpen(true);
        }
    };

    const closeModal = () => {
        // Clear the selected event and hide the modal
        setSelectedEvent(null);
        setBookingOpen(false);
    };

    const handleCancellation = (booking) => {
        setCancelling(true)
        axios.post(`${process.env.REACT_APP_API_URL}/bookings/cancel-booking`, booking)
        .then((res) => {
            toast.success(res.data);
            getBookings()
            setCancelling(false)
        })
        .catch((error) => {
            console.log(error)
            setCancelling(false)
        })
    }

    const passAlreadyOwned = (passId) => {
        const isValidPassAlreadyOwned = ownPasses.some((ownPass) =>
            ownPass.passId === passId
        );

        return isValidPassAlreadyOwned;
    }

    function getPassExpiry(pass) {
        const matchingOwnpass = ownPasses.find((own) => own.passId === pass._id);
        if (matchingOwnpass) {
            return moment(matchingOwnpass.expiry).format('Do MMM YYYY');
        } else {
            return moment().add(pass.weeksValid, 'weeks').format('Do MMM YYYY');
        }
    }

    const handlePurchasePass = (pass) => {
        if (!currentUser) {
            // Open login modal
            setLoginOpen(true)
        } else {
            // Handle Purchase
            setSelectedPass(pass)
            setPassOpen(true)
        }
    };

    const handlePassPurchased = () => {
        getOwnPasses()
    }

    return (
      <div>
        {shareOpen && <ModalShare setOpen={setShareOpen}/>}

        {loginOpen &&
            <ModalLogin
                setOpen={setLoginOpen}
                setOpenSignup={setOpenSignup}
            />
        }
        {openSignup &&
            <ModalSignUp
                setOpen={setOpenSignup}
                setOpenLogin={setLoginOpen}
            />
        }

        {bookingOpen && selectedEvent &&
            <ModalBooking
                event={selectedEvent}
                onClose={closeModal}
                getBookings={getBookings}
                bookings={bookings}
            />
        }

        {passOpen && selectedPass &&
            <ModalPassPurchase
                onSuccess={handlePassPurchased}
                setOpen={setPassOpen}
                pass={selectedPass}
                teacher={teacherInfo}
            />
        }

        <ProfileHeader />
        <div className='profile-container'>
            <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'baseline', flexWrap: 'wrap'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <button style={{background: 'unset', border: 'unset', padding: 0}} onClick={() => navigate('/explore?tab=1')}>
                        <img style={{height: 30, width: 30 , marginRight: 20}} src={back} alt='Om Yoga back button'/>
                    </button>
                    <h1 style={{color: '#313131'}}>{teacherInfo.firstName} {teacherInfo.lastName}</h1>
                </div>
                <div style={{display:'flex'}}>
                    <div className='hover' style={{display:'flex', alignItems: 'baseline', marginRight: 20}} onClick={() => setShareOpen(true)}>
                        <img style={{height: 22, width: 22, marginRight: 10}} src={share} alt='Share Om Yoga teacher profile'/>
                        <p>Share</p>
                    </div>

                    <div
                        className='hover'
                        style={{display:'flex', alignItems: 'baseline'}}
                        onClick={ following
                            ?
                            unFollowTeacher
                            :
                            followTeacher
                        }
                    >
                        <img style={{height: 25, width: 25, marginRight: 10}} src={following ? followingIcon : follow} alt='Follow Om Yoga teacher profile'/>
                        <p>{following ? "Following" : "Follow"}</p>
                    </div>
                </div>
            </div>

            <div style={{display:'flex', flexWrap: 'wrap'}}>

                <div className='profile-left'>
                    <img className='primary-img' src={teacherInfo.img}/>
                    <div className='profile-links'>
                        <div>

                        </div>

                        <div>
                            {teacherInfo.socials?.facebook &&
                                <a href={teacherInfo.socials.facebook} target='_blank'>
                                    <img style={{width: 40, height: 40, margin: 5}} src={fb} alt='Om Yoga social media link'/>
                                </a>
                            }
                            {teacherInfo.socials?.instagram &&
                                <a href={teacherInfo.socials.instagram} target='_blank'>
                                    <img style={{width: 40, height: 40, margin: 5}} src={insta} alt='Om Yoga social media link'/>
                                </a>
                            }

                        </div>
                    </div>

                    <div className='divider' />
                    <textarea ref={textareaRef} className='profile-bio' value={teacherInfo.bio}>
                    </textarea>

                    <div className='divider' />

                    {passes.map((pass, i) => {
                        return (
                            <div key={i} className='teacher-pass'>
                                <div className='left-hole'/>
                                    <h3 className='pass-title'>{pass.passName}</h3>

                                    <h4 style={{color:'white', margin: 10}}>£{pass.price}</h4>

                                    <button
                                        className='purchase-pass-btn'
                                        onClick={() => handlePurchasePass(pass)}
                                        disabled={passAlreadyOwned(pass._id)}
                                    >
                                        {passAlreadyOwned(pass._id) ? "Purchased" : "BUY NOW"}
                                    </button>

                                    <p style={{ color: 'grey', fontSize: 10}}>
                                        Valid until {getPassExpiry(pass)}
                                    </p>

                                <div className='right-hole'/>

                            </div>
                        )
                    })}
                </div>

                <div className='profile-right'>
                    <h3 style={{marginTop: 0, color: '#313131'}}>Upcoming Events</h3>

                    {events.length < 1 ?
                    <p>No events</p>

                    :
                    events.map((event, i) => {

                        let bookedEvent = bookings.filter((booking) => booking.eventId === event._id)
                        return (
                            <div className='profile-event' key={i}>
                                <div className='class-date-sml'>
                                    <div className='class-month-sml'>
                                        <p style={{fontSize: 14,color:'white', margin: 0}}>{moment(event.start).format('MMMM')}</p>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding: 5}}>
                                        <p style={{fontSize: 18, margin: 0}}>{moment(event.start).format('DD')}</p>
                                        <p style={{color: '#a28f89', fontWeight: 'bold', margin: 0, fontSize: 12}}>{moment(event.start).format('dddd')}</p>
                                    </div>

                                </div>
                                <div style={{width: '50%'}}>
                                    <p style={{fontSize: 20, marginBottom: 5}}>{event.className}</p>
                                    <p style={{color: '#717171', margin: 0, fontSize: 14}}>{event.venueName}</p>
                                    <p style={{color: '#717171', margin: 0, fontSize: 14}}>{moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}</p>

                                </div>
                                <div className='class-list-btns'>

                                {event.classType === 'Studio' ? (
                                    <a href={event.bookingLink} target='_blank' rel='noopener noreferrer'>
                                        <button className='om-btn'>
                                            External Booking
                                        </button>
                                    </a>
                                ) : (
                                    <button
                                        className='om-btn'
                                        style={bookedEvent.length > 0 ? {borderColor: 'green', color: 'green'} : null}
                                        onClick={() => handleBookNow(event)}
                                        disabled={bookedEvent.length > 0}
                                    >
                                        {bookedEvent.length > 0 ? "Booked" : "Book Now"}
                                    </button>
                                )}

                                {bookedEvent.length > 0 &&
                                    <button
                                        className='om-btn'
                                        style={{borderColor: 'crimson', color: 'crimson', borderWidth: 1, marginTop: 10}}
                                        onClick={() => handleCancellation(bookedEvent[0])}
                                        disabled={cancelling}
                                    >
                                        {cancelling ? "Cancelling" : "Cancel Booking"}
                                    </button>
                                }

                                {event.googleMaps &&
                                    <a href={event.googleMaps} style={{textDecoration:'none'}} target='_blank'>
                                        <button className='map-btn'>
                                            <img style={{height: 25, width: 25}} src={map} alt='Om Yoga booking app link to google maps'/>
                                            <p style={{color:'white'}}>View on Map</p>
                                        </button>
                                    </a>
                                }
                                </div>
                            </div>
                        )
                    })
                    }
                </div>

            </div>


        </div>
        <Footer/>

    </div>
  )
}
